import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	IconModule,
	RadioButtonModule,
	TabModule,
	ToastModule,
	TooltipModule
} from '@nielseniq/athena-core';
import { GridModule } from 'src/app/shared/components/grid/grid.module';
import { HeaderComponent } from './report/header/header.component';
import { ComponentModule } from './selector/component.module';
import { GisMapComponent } from './gis-map/gis-map.component';
import { ClickOutsideDirective } from 'src/app/core/directive/promptclickoutside.directive';

@NgModule({
	declarations: [HeaderComponent, GisMapComponent],
	imports: [
		ClickOutsideDirective,
		CommonModule,
		IconModule,
		TooltipModule,
		ComponentModule,
		TabModule,
		GridModule,
		ToastModule,
		RadioButtonModule
	],
	exports: [HeaderComponent, TabModule, GisMapComponent]
})
export class SharedComponentModule {}

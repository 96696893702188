import { Component, OnDestroy, OnInit } from '@angular/core';
import { NlsnGlobalsContextExtract } from '../core/service/auth/nlsn-globals.service';
import { AppInsightsService } from '../service/app-insights.service';
import { APP_INSIGHTS_KEY } from 'src/environments/env.config.constants';
import { Location } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { ToastService } from '@nielseniq/athena-core';
import { filter, takeUntil } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';

@Component({
	selector: 'crf-ca-page',
	templateUrl: './page.component.html',
	styleUrl: './page.component.scss'
})
export class PageComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();

	constructor(
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract,
		private appInsightsService: AppInsightsService,
		private location: Location,
		private router: Router,
		private toastService: ToastService
	) {}

	ngOnInit() {
		// Initialize app insights with appId and appName
		this.appInsightsService.initialize(
			APP_INSIGHTS_KEY[this.nlsnGlobalsContextExtract.extractEnvironment()],
			undefined
		);

		const navigationEvent$ = this.router.events.pipe(
			filter(event => event instanceof NavigationStart)
		);

		const locationEvent$ = new Subject<void>();
		this.location.subscribe(() => locationEvent$.next());

		merge(navigationEvent$, locationEvent$)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.toastService.DismissToast();
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

@Pipe({
	name: 'zip',
	standalone: true
})
export class ZipPipe implements PipeTransform {
	constructor(private _decimalPipe: DecimalPipe, private currencyPipe: CurrencyPipe) {}

	// Added some check for the Leakage tree change
	transform(value: number | string, formatType: string): unknown {
		let outputValue: any = 0;

		if (value === undefined) {
			return '';
		}

		if (
			formatType === 'prodName' ||
			value === '' ||
			(typeof value === 'string' &&
				formatType !== 'perc' &&
				formatType !== 'dollar' &&
				formatType !== 'roundednumber')
		) {
			return value;
		} else if (formatType === 'dollar2decimal') {
			outputValue = this.currencyFilter(value);
		} else if (formatType === 'dollar') {
			//rounded dollar values
			if (value == 'RO') {
				outputValue = 'RO';
			} else {
				outputValue = this.currencyFilter(value, '$');
			}
		} else if (formatType === 'perc') {
			if (value == 'RO') {
				outputValue = 'RO';
			} else {
				outputValue = this.numberFilter(value, '1.0-1') + '%';
			}
		} else if (formatType === 'roundednumber' || formatType === 'roundeddollar') {
			outputValue = this.numberFilter(value, 0);
		}
		return outputValue;
	}

	numberFilter(num, decimal) {
		return this._decimalPipe.transform(num, decimal);
	}
	currencyFilter(input, currency?) {
		// added 2 decimal if change need to updated in leakage  tree
		return this.currencyPipe.transform(input, currency, true, '1.0-2');
	}
}

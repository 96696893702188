import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeographicDeepDriveService } from './service/geographic-deep-drive.service';
import { SELECTOR } from 'src/app/shared/constants/selector.constant';
import { pageHoverTexts } from 'src/app/models/report-hovertexts.constant';
import { SaveDefaultComponent } from 'src/app/shared/components/selector/save-default/save-default.component';
import {
	BY_LEVEL_DISPLAY_LABEL,
	BY_LEVEL_REQUEST_TYPE,
	EXPORT_NAME,
	PPT
} from 'src/app/constants/report.constant';
import { ReportService } from 'src/app/service/report.service';
import { ToastService } from '@nielseniq/athena-core';
import { TOAST } from 'src/app/constants/toast.constant';
import { map, Subject, takeUntil } from 'rxjs';
import { ProductSearchService } from 'src/app/service/product-search.service';
import { GEOGRAPHIC_DEEP_DIVE_DASHBOARD } from 'src/app/constants/dashboard-ui.constant';
import * as _ from 'lodash';

import { BASE_FACT_DROPDOWN, FACT_SPECIFICATION } from './constants/geographic-deep-dive.constant';
import { GridService } from 'src/app/shared/components/grid/grid-service/grid.service';
import { UtilityService } from '../services/utility.service';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import { GisMapComponent } from 'src/app/shared/components/gis-map/gis-map.component';
import { TopbarUtilityService } from 'src/app/core/service/topbar/topbar-utility.service';
import { ReportDimension } from 'src/app/models/report-dimension.model';
import { $ } from 'protractor';

@Component({
	selector: 'crf-ca-geographic-deep-dive',
	templateUrl: './geographic-deep-dive.component.html',
	styleUrl: './geographic-deep-dive.component.scss'
})
export class GeographicDeepDiveComponent implements OnInit, OnDestroy {
	reportName = EXPORT_NAME.GEODEEPDIVE;
	positiveMapTableValues = [];
	negativeMapTableValues = [];
	legendData: any;
	baseFact: any;
	marketTotalUS: any;
	tableColumnConfig: (
		| {
				headerName: string;
				field: string;
				width: number;
				cellClass: string;
				cellFilter?: undefined;
				type?: undefined;
				displayName?: undefined;
				sort?: undefined;
		  }
		| {
				headerName: string;
				field: string;
				width: number;
				cellFilter: string;
				cellClass: string;
				type?: undefined;
				displayName?: undefined;
				sort?: undefined;
		  }
		| {
				field: string;
				headerName: string;
				type: string;
				displayName: string;
				width: number;
				cellFilter: string;
				cellClass: string;
				sort: string;
		  }
		| {
				field: string;
				headerName: string;
				type: string;
				displayName: string;
				width: number;
				cellFilter: string;
				cellClass: string;
				sort?: undefined;
		  }
		| {
				field: string;
				headerName: string;
				type: string;
				width: number;
				cellFilter: string;
				cellClass: string;
				displayName?: undefined;
				sort?: undefined;
		  }
	)[];

	baseFactSymbol: string;

	selectedMarket = 'Total US';
	isLoading: boolean;
	isMapDataReady: boolean;
	noData: boolean;
	metrics: any;
	marketPosts: any;

	private destroySubject: Subject<void> = new Subject();

	pageHoverTexts = pageHoverTexts;
	levelDisplayName = GEOGRAPHIC_DEEP_DIVE_DASHBOARD.HEADER_NAMES;

	questionId = 4;
	activeTab = 'map';
	productLevel = BY_LEVEL_REQUEST_TYPE;
	requestPayload: any = [];

	defaultPayload: any = {};

	@ViewChild(SaveDefaultComponent) saveDefaultComponent: SaveDefaultComponent;
	@ViewChild(GisMapComponent) gisMap: GisMapComponent;

	headerConfig = {
		product: {
			value: {
				display: '',
				selectedProduct: []
			},
			ui: {
				display: false,
				disabled: false
			},
			config: {
				id: SELECTOR.PRODUCT.ID,
				multiSelect: true,
				apiFetched: true,
				available: true
			}
		},
		bylevel: {
			value: {
				display: '',
				dropdown: []
			},
			ui: {
				display: false,
				disabled: false
			},
			config: {
				id: SELECTOR.BYLEVEL.ID,
				multiSelect: false,
				apiFetched: false,
				available: false
			}
		},
		period: {
			value: {
				display: '',
				initial: [],
				actual: [],
				dropdown: []
			},
			ui: {
				display: false,
				disabled: false
			},
			config: {
				id: SELECTOR.PERIOD.ID,
				multiSelect: false,
				apiFetched: false,
				available: false
			}
		},
		basefact: {
			value: {
				display: '',
				initial: [],
				actual: [],
				dropdown: []
			},
			ui: {
				display: false,
				disabled: false
			},
			config: {
				id: SELECTOR.BASE_FACT.ID,
				multiSelect: false,
				apiFetched: false,
				available: false
			}
		}
	};
	periodData: any = {};
	lastLevel: string;
	byLevel: any;
	columDefs: any = [...FACT_SPECIFICATION];
	columnSortConfig: { headerName: string; sortOrder: string };
	selectedRegionValue: number | string;
	pageTitle: string;
	geographicDeepDiveCtrl: ReportDimension = {
		dashboardName: 'Geographic Deep Dive (Scan)',
		selectedMarket: ['Total US'],
		twoYAEnabled: true
	};

	constructor(
		private readonly geograhicDeepDiveService: GeographicDeepDriveService,
		private readonly reportService: ReportService,
		private readonly toastService: ToastService,
		private readonly productSearchService: ProductSearchService,
		private readonly gridColumnService: GridService,
		private readonly utilityService: UtilityService,
		private topBarUtility: TopbarUtilityService
	) {}

	ngOnInit() {
		this.pageTitle = this.topBarUtility.getPageTitleReport();

		this.isLoading = true;
		this.getDefaultSelectors();
		// Grid column subscription
		this.gridColumnService.sortColumnSubject$
			.pipe(takeUntil(this.destroySubject))
			.subscribe(res => {
				this.columnSortConfig = res;
			});
	}
	ngOnDestroy(): void {
		// Unsubscribe from all observables
		this.gridColumnService.resetColumnConfig();
		this.destroySubject.next();
		this.destroySubject.complete();
	}
	onTabChange(selectedTab: string) {
		this.closeAllDropdown();
		this.activeTab = selectedTab;
	}
	toggleDropdown(selectorId: string): void {
		Object.keys(this.headerConfig).forEach(selectorKey => {
			if (selectorKey !== selectorId) this.headerConfig[selectorKey].ui.display = false;
			else this.headerConfig[selectorKey].ui.display = !this.headerConfig[selectorKey].ui.display;
		});
	}
	closeAllDropdown(): void {
		Object.keys(this.headerConfig).forEach(
			selectorKey => (this.headerConfig[selectorKey].ui.display = false)
		);
		this.saveDefaultComponent.showMoreOptions = false;
	}

	saveResetDefault(event: any, type: string) {
		let payload: any = {};
		if (event.menuId == 1) {
			//Save defaults
			switch (type) {
				case 'all':
					Object.keys(this.productLevel).forEach(key => {
						payload = { ...payload, [key]: this.convertArrayToString(this.requestPayload[key]) };
					});
					payload = {
						...payload,
						periodIds: this.headerConfig.period.value.display,
						baseFact: this.baseFact
					};
					break;
				case 'periodIds':
					payload = { periodIds: this.headerConfig.period.value.display };
					this.defaultPayload.periodIds = payload.periodIds;
					break;
				case 'baseFact':
					payload = { baseFact: this.baseFact };
					this.defaultPayload.baseFact = payload.baseFact;
					break;
			}
			this.reportService
				.saveSelectors(this.questionId, type, payload)
				.pipe(takeUntil(this.destroySubject))
				.subscribe({
					next: res => {
						this.toastService.InjectToast(
							'success',
							'Selections saved successfully',
							'',
							TOAST.TIMEOUT,
							'',
							'Success',
							TOAST.SIZE
						);
					},
					error: err => console.log(err)
				});
		} else if (event.menuId == 2) {
			switch (type) {
				case 'all':
					this.getDefaultSelectors();
					break;
				case 'periodIds': {
					Object.keys(this.periodData).forEach(item => {
						if (!_.isEmpty(this.defaultPayload.periodIds)) {
							if (this.defaultPayload.periodIds === item) {
								this.setPeriodData(item);
							}
						} else if (this.periodData[item].defaultPeriod) {
							this.setPeriodData(item);
						}
					});
					let config = {
						key: this.headerConfig.period.value.display.split(' ')[1],
						value: this.headerConfig.period.value.display
					};
					let index = this.headerConfig.period.value.dropdown.findIndex(
						x => x.value === this.periodData.default_key
					);
					this.headerConfig.period.value.dropdown.forEach((element, idx) => {
						element.isSelected = false;
						if (index === idx) {
							element.isSelected = true;
						}
					});
					this.valueChangeHandler(config, 'period');
					this.utilityService.preselectDefault(
						this.headerConfig.period.value.dropdown,
						this.periodData.default_key
					);
					break;
				}
				case 'baseFact': {
					let factValue = this.columDefs.filter(
						fact => fact.field === this.defaultPayload.baseFact
					);
					this.setBaseFacts(
						factValue[0].headerName,
						this.defaultPayload.baseFact,
						factValue[0].cellFilter?.split("'")[1]
					);
					this.headerConfig.basefact.value.dropdown.forEach(item => {
						item.isSelected = item.key === this.defaultPayload.baseFact;
					});
					break;
				}
			}
		}
		this.closeAllDropdown();
	}

	// convert [A,B,C] ==> A;B;C
	convertArrayToString(requestedArray) {
		return requestedArray.toString().replaceAll(',', ';');
	}
	getDefaultSelectors() {
		this.reportService
			.getSelectors(this.questionId)
			?.pipe(takeUntil(this.destroySubject))
			.subscribe(response => {
				this.resetAll();
				this.createRequestPayload(response.responseData);
			});
	}
	resetAll() {
		this.headerConfig = {
			product: {
				value: {
					display: '',
					selectedProduct: []
				},
				ui: {
					display: false,
					disabled: false
				},
				config: {
					id: SELECTOR.PRODUCT.ID,
					multiSelect: true,
					apiFetched: true,
					available: true
				}
			},
			bylevel: {
				value: {
					display: '',
					dropdown: []
				},
				ui: {
					display: false,
					disabled: false
				},
				config: {
					id: SELECTOR.BYLEVEL.ID,
					multiSelect: false,
					apiFetched: false,
					available: false
				}
			},
			period: {
				value: {
					display: '',
					initial: [],
					actual: [],
					dropdown: []
				},
				ui: {
					display: false,
					disabled: false
				},
				config: {
					id: SELECTOR.PERIOD.ID,
					multiSelect: false,
					apiFetched: false,
					available: false
				}
			},
			basefact: {
				value: {
					display: '',
					initial: [],
					actual: [],
					dropdown: []
				},
				ui: {
					display: false,
					disabled: false
				},
				config: {
					id: SELECTOR.BASE_FACT.ID,
					multiSelect: false,
					apiFetched: false,
					available: false
				}
			}
		};
	}
	loadTablePost() {
		this.isLoading = true;
		this.geograhicDeepDiveService
			.getTablePost(this.requestPayload)
			.pipe(takeUntil(this.destroySubject))
			.subscribe(res => {
				this.metrics = res.metrics;
				this.isLoading = false;
				// Call sortdata method for HTML table
				this.sortedDataForTable();
			});
	}

	sortedDataForTable() {
		this.positiveMapTableValues = [];
		this.negativeMapTableValues = [];
		this.metrics.forEach(item => {
			if (item[this.baseFact] > 0) {
				this.positiveMapTableValues.push(item);
			} else {
				this.negativeMapTableValues.push(item);
			}
		});
		// Sort positiveMapTableValues in descending order
		this.positiveMapTableValues = this.positiveMapTableValues
			.sort((a, b) => b[this.baseFact] - a[this.baseFact])
			.splice(0, 10);

		// Sort negativeMapTableValues in descending order
		this.negativeMapTableValues = this.negativeMapTableValues
			.sort((a, b) => a[this.baseFact] - b[this.baseFact])
			.splice(0, 10);
	}

	loadMarketPost() {
		this.isMapDataReady = false;
		this.isLoading = true;
		this.geograhicDeepDiveService
			.getMarketPost(this.requestPayload)
			.pipe(takeUntil(this.destroySubject))
			.subscribe(res => {
				this.marketPosts = res.markets;
				this.marketTotalUS = res.totalUS;
				if (_.isEmpty(res)) {
					this.noData = true;
				}
				this.isLoading = false;

				if (this.baseFact !== 'Total US') {
					let marketValue = res.markets
						.filter(x => x.market === this.selectedMarket)
						.map(x => x[this.baseFact])[0];

					this.selectedRegionValue = marketValue;
				}

				this.createRender(this.baseFact);
			});
	}

	setPeriodData(item) {
		this.headerConfig.period.config.available = true;
		this.headerConfig.period.value.display = item;
		this.geographicDeepDiveCtrl.period = item;
		this.periodData.default_key = item;
		this.periodData.cyPeriod = this.periodData[item].currentPeriod;
		this.periodData.yaPeriod = this.periodData[item].previousPeriod;
		this.periodData.twoYAPeriod = this.periodData[item].twoYAPeriod;
		this.periodData.id = this.periodData[item].id;
	}
	createRequestPayload(request) {
		//base fact relection shoudl be present in the payload

		this.defaultPayload = request;
		let selectedProduct = [];
		let tempProduct = {
			department: request.department ? request.department.split(';') : [],
			categoryGroup: request.categorygroup ? request.categorygroup.split(';') : [],
			category: request.category ? request.category.split(';') : [],
			subCategory: request.subcategory ? request.subcategory.split(';') : [],
			segment: request.segment ? request.segment.split(';') : [],
			brandOwner: request.brandownerhigh ? request.brandownerhigh.split(';') : [],
			brandOwnerLow: request.brandownerlow ? request.brandownerlow.split(';') : [],
			brandHigh: request.brandhigh ? request.brandhigh.split(';') : [],
			brandFamily: request.brandfamily ? request.brandfamily.split(';') : [],
			brandLow: request.brandlow ? request.brandlow.split(';') : [],
			brand: request.brand ? request.brand.split(';') : [],
			totalBusiness: request.totalbusiness ? request.totalbusiness.split(';') : [],
			majorBusiness: request.majorbusiness ? request.majorbusiness.split(';') : [],
			market: [this.selectedMarket]
		};
		// Set default value with
		this.requestPayload = { ...tempProduct };
		Object.keys(this.productLevel).forEach(element => {
			if (!_.isEmpty(tempProduct[element])) {
				selectedProduct.push({ [element]: tempProduct[element] });
				this.lastLevel = element;
			}
		});
		// Load basefact

		this.productFooterView(selectedProduct);
		this.headerConfig.product.value.display = selectedProduct.slice(-1)[0][this.lastLevel];
		this.headerConfig.basefact.value.dropdown = BASE_FACT_DROPDOWN;
		if (request.baseFact) {
			let factValue = this.columDefs.filter(fact => fact.field === request.baseFact);
			this.setBaseFacts(
				factValue[0].headerName,
				request.baseFact,
				factValue[0].cellFilter?.split("'")[1]
			);
		} else {
			this.setBaseFacts('WM $ Share Pt Chg (BPS) vs YA', 'sharePTChg', 'roundednumber');
		}
		this.headerConfig.basefact.config.available = true;
		this.getChildLevel(this.lastLevel);
		this.loadPeriods();
	}
	setBaseFacts(display, fact, baseFactSymbol) {
		this.headerConfig.basefact.value.display = display;
		this.geographicDeepDiveCtrl.fact = display;
		this.headerConfig.basefact.value.dropdown.forEach(item => {
			if (item.value === this.headerConfig.basefact.value.display) {
				item.isSelected = true;
			}
		});
		this.baseFact = fact;
		this.baseFactSymbol = baseFactSymbol;

		this.columnSortConfig = {
			headerName: display,
			sortOrder: 'asc'
		};
		this.columDefs = this.columDefs.map(ele =>
			ele.headerName === display ? { ...ele, sort: 'asc' } : { ...ele, sort: null }
		);
	}
	loadPeriods() {
		this.reportService
			.getPeriod()
			.pipe(
				map(period => period.responseData),
				takeUntil(this.destroySubject)
			)
			.subscribe(periodResp => {
				if (!_.isEmpty(periodResp)) {
					this.periodData = periodResp;
					Object.keys(this.periodData).forEach(item => {
						if (!_.isEmpty(this.defaultPayload.periodIds)) {
							if (this.defaultPayload.periodIds === item) {
								this.setPeriodData(item);
							}
						} else if (this.periodData[item].defaultPeriod) {
							this.setPeriodData(item);
						}
						this.headerConfig.period.value.dropdown.push({
							key: this.periodData[item].id,
							value: item,
							isSelected: false
						});
					});
					this.requestPayload = {
						...this.requestPayload,
						cyPeriod: this.periodData.cyPeriod,
						yaPeriod: this.periodData.yaPeriod,
						twoYAPeriod: this.periodData.twoYAPeriod
					};
				}

				this.headerConfig.period.value.dropdown.forEach(item => {
					if (item.value === this.headerConfig.period.value.display) {
						item.isSelected = true;
					}
				});

				this.getPeriodEndDate();
				this.loadTablePost();
				this.loadMarketPost();
			});
	}

	getPeriodEndDate() {
		// Remove unused keys-> valuea
		let periodEndPayload = _.omit(this.requestPayload, [
			'rmMarket',
			'wmMarket',
			'cyPeriod',
			'yaPeriod',
			'twoYAPeriod'
		]);
		this.reportService
			.getPeriodEndDate(this.questionId, this.periodData.id, periodEndPayload)
			.pipe(takeUntil(this.destroySubject))
			.subscribe(res => {
				this.geographicDeepDiveCtrl.periodEndDate = res.periodEndDate;
			});
	}

	getChildLevel(level) {
		this.productSearchService
			.getChildLevels(this.questionId, this.productLevel[level])
			.pipe(takeUntil(this.destroySubject))
			.subscribe(response => {
				let responseData = response.result.map(byLevel => {
					return {
						...byLevel,
						isSelected: false
					};
				});
				let index = responseData.findIndex(x => x.key === this.productLevel[level]);
				// Only add 1 to the index if it's not the last index
				if (index < responseData.length - 1) {
					index += 1;
				}
				this.headerConfig.bylevel.config.available = true;
				this.headerConfig.bylevel.value.dropdown = responseData;
				this.headerConfig.bylevel.value.display = responseData[index]?.value;
				this.geographicDeepDiveCtrl.selectedLevelToShow = this.headerConfig.bylevel.value.display;
				responseData[index].isSelected = true;

				this.byLevel = responseData[index]?.key;
				this.requestPayload = { ...this.requestPayload, byprodlevel: this.byLevel };

				this.columDefs[0].headerName = responseData[index]?.value;
			});
	}
	/**
	 *
	 * @param products
	 * product=[
	 * {filter:"A",values:[a,b,c]}
	 * ]
	 */
	productFooterView(products) {
		let footerLevelViewData = [];
		products.forEach(element => {
			footerLevelViewData.push({
				['filter']: this.levelDisplayName[this.productLevel[Object.keys(element)[0]]],
				['value']: Object.values(element)
			});
		});
		this.headerConfig.product.value.selectedProduct = footerLevelViewData;
		this.geographicDeepDiveCtrl.selectedProduct = this.reportService.setSelectedProduct(
			this.headerConfig.product.value.selectedProduct,
			true
		);
	}
	valueChangeHandler($event: any, key: string) {
		switch (key) {
			case SELECTOR.PRODUCT.ID: {
				this.requestPayload = { ...this.requestPayload, ...$event };
				if (this.requestPayload.brandOwnerHigh) {
					this.requestPayload.brandOwner = this.requestPayload.brandOwnerHigh;
					delete this.requestPayload.brandOwnerHigh;
				}
				let products = [];
				Object.keys($event).forEach(element => {
					if (!_.isEmpty($event[element])) {
						products.push({ [element]: $event[element] });
						this.lastLevel = element;
					}
				});
				this.headerConfig.product.value.display = products?.slice(-1)[0][this.lastLevel];
				this.productFooterView(products);

				// Need to optimize
				let level = Object.keys(this.productLevel);
				let index = level.indexOf(this.lastLevel);
				this.byLevel = this.productLevel[level[index + 1]] || this.productLevel[level[index]];
				this.requestPayload = { ...this.requestPayload, byprodlevel: this.byLevel };
				this.getChildLevel(this.lastLevel);
				break;
			}
			case SELECTOR.BASE_FACT.ID: {
				let factValue = this.columDefs.filter(fact => fact.field === $event.key);
				this.setBaseFacts($event.value, $event.key, factValue[0].cellFilter?.split("'")[1]);
				this.sortedDataForTable();
				this.createRender(this.baseFact);

				if (this.selectedMarket !== 'Total US') {
					let marketValue = this.marketPosts
						.filter(x => x.market === this.selectedMarket)
						.map(x => x[this.baseFact])[0];

					this.selectedRegionValue = marketValue;
				}
				break;
			}
			case SELECTOR.BYLEVEL.ID:
				this.byLevel = $event.key;
				this.headerConfig.bylevel.value.display = $event.value;
				this.geographicDeepDiveCtrl.selectedLevelToShow = this.headerConfig.bylevel.value.display;

				this.columDefs[0].headerName = $event.value;
				this.requestPayload = {
					...this.requestPayload,
					byprodlevel: this.byLevel
				};
				break;
			case SELECTOR.PERIOD.ID:
				// Used in drill down level scenerio
				let item = $event.value;
				// display header value
				this.headerConfig.period.value.display = item;
				this.geographicDeepDiveCtrl.period = item;
				this.periodData.default_key = item;
				this.periodData.cyPeriod = this.periodData[item].currentPeriod;
				this.periodData.yaPeriod = this.periodData[item].previousPeriod;
				this.periodData.twoYAPeriod = this.periodData[item].twoYAPeriod;
				this.periodData.id = this.periodData[item].id;
				// update payload
				this.requestPayload = {
					...this.requestPayload,
					cyPeriod: this.periodData.cyPeriod,
					yaPeriod: this.periodData.yaPeriod,
					twoYAPeriod: this.periodData.twoYAPeriod
				};
				break;

			default:
				break;
		}

		this.closeAllDropdown();
		if (key !== SELECTOR.BASE_FACT.ID) {
			if (key == SELECTOR.BYLEVEL.ID) {
				this.loadTablePost();
			} else {
				this.loadTablePost();
				this.loadMarketPost();
			}
		}
	}
	reportDownload(type) {
		let exportPayload: any;
		if (type === 'excel') {
			exportPayload = {
				...this.requestPayload,
				applicationType: 'JBP',
				dataModelType: 'GEODEEPDIVE',
				bylevel: this.headerConfig.bylevel.value.display,
				periodEndDate: this.geographicDeepDiveCtrl.periodEndDate,
				scope: this.lastLevel,
				fact: this.baseFact,
				factType: this.headerConfig.basefact.value.display,
				period: this.headerConfig.period.value.display,
				isTotalMarket: this.selectedMarket === 'Total US' ? true : false
			};
			if (exportPayload.brandOwner) {
				exportPayload.brandOwnerHigh = exportPayload.brandOwner;
				delete exportPayload.brandOwner;
			}
			this.downloadFile(type, exportPayload);
		}
		if (type === PPT) {
			let productValue = this.headerConfig.product.value.display.toString();
			exportPayload = {
				applicationType: 'JBP',
				dataModelType: 'GEODEEPDIVE',
				bylevel: this.headerConfig.bylevel.value.display,
				byprodlevel: this.requestPayload.byprodlevel,
				cyPeriod: this.requestPayload.cyPeriod,
				fact: this.baseFact,
				factType: this.headerConfig.basefact.value.display,
				isTotalMarket: this.selectedMarket === 'Total US' ? true : false,
				level: this.baseFact,
				levelHeaderName: this.headerConfig.bylevel.value.display,
				market: this.requestPayload.market,
				marketValue:
					this.selectedMarket === 'Total US'
						? this.marketTotalUS[this.baseFact]
						: this.selectedRegionValue,
				period: this.headerConfig.period.value.display,
				periodEndDate: this.geographicDeepDiveCtrl.periodEndDate,
				prodlevel: BY_LEVEL_REQUEST_TYPE[this.lastLevel],
				prodvalue: [productValue],
				scope: BY_LEVEL_DISPLAY_LABEL[this.lastLevel],
				scopeDisplayValue: this.headerConfig.product.value.display.toString(),
				selectedValue: productValue,
				tableData: {
					bottom: this.negativeMapTableValues,
					main: this.metrics,
					top: this.positiveMapTableValues
				},
				twoYAPeriod: this.requestPayload.twoYAPeriod,
				yaPeriod: this.requestPayload.yaPeriod,
				legendData: this.legendData
			};
			this.gisMap.takeScreenshot().then(base64Image => {
				exportPayload.imageBase64 = base64Image;
				this.downloadFile(type, exportPayload);
			});
		}

		this.closeAllDropdown();
	}

	downloadFile(type, payload) {
		this.utilityService.exportFile(payload, type);
	}
	// MAP Code Start
	getRenderConfig(view) {
		let fIndex = [
			'marketShare',
			'salesPerChg',
			'salesPerChgTwoYA',
			'unitsMarketShare',
			'unitsSalesChg',
			'unitsSalesChgTwoYA',
			'salesActualChg',
			'salesActualChgTwoYA',
			'unitsActualChg',
			'unitsActualChgTwoYA',
			'dollarValueOfShrPTChg',
			'dollarValueOfShrPTChgTwoYA',
			'unitValueOfShrPTChg',
			'unitValueOfShrPTChgTwoYA',
			'eqUnitsMarketShare',
			'eqUnitValueOfShrPTChg',
			'eqUnitValueOfShrPTChgTwoYA',
			'eqUnitsSalesChg',
			'eqUnitsSalesChgTwoYA',
			'eqUnitsActualChg',
			'eqUnitsActualChgTwoYA'
		].indexOf(view);
		let formattingNeeded = fIndex > -1;
		let formattingType = formattingNeeded ? ['%', '%', '%', '%', '$', '', '$', ''][fIndex] : '';
		let filterByValue = view;
		let topValueLegend = [],
			bottomValueLegend: any = [],
			cValues = [];
		let topVal, bottomVal, topRagediff, bottomRangediff;

		let maxValue = _.maxBy(this.marketPosts, o => {
			return typeof o[filterByValue] === 'number' ? o[filterByValue] : parseInt(o[filterByValue]);
		});

		let minValue = _.minBy(this.marketPosts, o => {
			return typeof o[filterByValue] === 'number' ? o[filterByValue] : parseInt(o[filterByValue]);
		});

		//Dividing ranges into 6 sections top 3 and bottom 3
		if (!maxValue && !minValue) {
			return 'NA';
		}

		topVal = maxValue[filterByValue];
		bottomVal = minValue[filterByValue];

		if (formattingNeeded) {
			topRagediff = topVal < 3 ? topVal : _.floor(maxValue[filterByValue] / 3, 1);
			bottomRangediff = bottomVal > -3 ? bottomVal : _.floor(minValue[filterByValue] / 3, 1);
		} else {
			topRagediff = topVal < 3 ? topVal : _.floor(maxValue[filterByValue] / 3);
			bottomRangediff = bottomVal > -3 ? bottomVal : _.floor(minValue[filterByValue] / 3);
		}

		let topRanges = {
			one: {
				max: formattingNeeded ? (topRagediff * 1).toFixed(1) : topRagediff * 1,
				min: formattingNeeded ? (0).toFixed(1) : 0,
				label: formattingNeeded
					? '' +
					  this.numberFormat(0, formattingType) +
					  ' to ' +
					  this.numberFormat(topRagediff * 1, formattingType) +
					  ''
					: '' + 0 + ' to ' + topRagediff * 1 + '',
				fill_color: [151, 219, 151, 1],
				outline_color: [128, 193, 128, 1]
			},
			two: {
				max: formattingNeeded ? (topRagediff * 2).toFixed(1) : topRagediff * 2,
				min: formattingNeeded ? (topRagediff * 1).toFixed(1) : topRagediff * 1,
				label: formattingNeeded
					? '' +
					  this.numberFormat(topRagediff * 1, formattingType) +
					  ' to ' +
					  this.numberFormat(topRagediff * 2, formattingType) +
					  ''
					: '' + topRagediff * 1 + ' to ' + topRagediff * 2 + '',
				fill_color: [93, 180, 93, 1],
				outline_color: [75, 154, 75, 1]
			},
			three: {
				max: formattingNeeded ? (topVal + 1).toFixed(1) : topVal + 1,
				min: formattingNeeded ? (topRagediff * 2).toFixed(1) : topRagediff * 2,
				label: formattingNeeded
					? '' +
					  this.numberFormat(topRagediff * 2, formattingType) +
					  ' to ' +
					  this.numberFormat(topVal + 1, formattingType) +
					  ''
					: '' + topRagediff * 2 + ' to ' + (topVal + 1) + '',
				fill_color: [38, 185, 16, 1],
				outline_color: [30, 154, 11, 1]
			}
		};

		let botttomRanges = {
			one: {
				max: formattingNeeded ? (0).toFixed(1) : 0,
				min: (bottomRangediff * 1).toFixed(1),
				label: formattingNeeded
					? '' +
					  this.numberFormat(bottomRangediff * 1, formattingType) +
					  ' to ' +
					  this.numberFormat(0, formattingType) +
					  ''
					: '' + bottomRangediff * 1 + ' to ' + 0 + '',
				fill_color: [255, 204, 204, 1],
				outline_color: [230, 177, 177, 1]
			},
			two: {
				max: formattingNeeded ? (bottomRangediff * 1).toFixed(1) : bottomRangediff * 1,
				min: formattingNeeded ? (bottomRangediff * 2).toFixed(1) : bottomRangediff * 2,
				label: formattingNeeded
					? '' +
					  this.numberFormat(bottomRangediff * 2, formattingType) +
					  ' to ' +
					  this.numberFormat(bottomRangediff * 1, formattingType) +
					  ''
					: '' + bottomRangediff * 2 + ' to ' + bottomRangediff * 1 + '',
				fill_color: [213, 88, 88, 1],
				outline_color: [162, 77, 77, 1]
			},
			three: {
				max: formattingNeeded ? (bottomRangediff * 2).toFixed(1) : bottomRangediff * 2,
				min: formattingNeeded ? (bottomVal - 1).toFixed(1) : bottomVal - 1,
				label: formattingNeeded
					? '' +
					  this.numberFormat(bottomVal - 1, formattingType) +
					  ' to ' +
					  this.numberFormat(bottomRangediff * 2, formattingType) +
					  ''
					: '' + (bottomVal - 1) + ' to ' + bottomRangediff * 2 + '',
				fill_color: [224, 0, 32, 1],
				outline_color: [145, 19, 37, 1]
			}
		};

		bottomValueLegend = [
			{
				minvalue: botttomRanges.three.min,
				maxvalue: botttomRanges.three.max,
				symbol_colors: botttomRanges.three.outline_color,
				symbol_fill: botttomRanges.three.fill_color,
				vlabel: botttomRanges.three.label
			},
			{
				minvalue: botttomRanges.two.min,
				maxvalue: botttomRanges.two.max,
				symbol_colors: botttomRanges.two.outline_color,
				symbol_fill: botttomRanges.two.fill_color,
				vlabel: botttomRanges.two.label
			},
			{
				minvalue: botttomRanges.one.min,
				maxvalue: botttomRanges.one.max,
				symbol_colors: botttomRanges.one.outline_color,
				symbol_fill: botttomRanges.one.fill_color,
				vlabel: botttomRanges.one.label
			}
		];

		topValueLegend = [
			{
				minvalue: topRanges.one.min,
				maxvalue: topRanges.one.max,
				symbol_colors: topRanges.one.outline_color,
				symbol_fill: topRanges.one.fill_color,
				vlabel: topRanges.one.label
			},
			{
				minvalue: topRanges.two.min,
				maxvalue: topRanges.two.max,
				symbol_colors: topRanges.two.outline_color,
				symbol_fill: topRanges.two.fill_color,
				vlabel: topRanges.two.label
			},
			{
				minvalue: topRanges.three.min,
				maxvalue: topRanges.three.max,
				symbol_colors: topRanges.three.outline_color,
				symbol_fill: topRanges.three.fill_color,
				vlabel: topRanges.three.label
			}
		];

		if (topVal < 3) {
			topValueLegend.splice(1, 2);
			topValueLegend[0]['minvalue'] = topRanges.one.min;
			topValueLegend[0]['maxvalue'] = topRanges.three.max + 1;
			topValueLegend[0]['symbol_colors'] = topRanges.one.outline_color;
			topValueLegend[0]['symbol_fill'] = topRanges.one.fill_color;
			topValueLegend[0]['vlabel'] = formattingNeeded
				? this.numberFormat(topRanges.one.min, formattingType) +
				  ' to ' +
				  this.numberFormat(topRanges.three.max + 1, formattingType)
				: '' + topRanges.one.min + ' to ' + topRanges.three.max + 1 + '';
		}

		if (bottomVal > -3) {
			bottomValueLegend.splice(1, 2);
			let data: any = botttomRanges.three.max;
			bottomValueLegend[0]['minvalue'] = data - 1;
			bottomValueLegend[0]['maxvalue'] = botttomRanges.one.max;
			bottomValueLegend[0]['symbol_colors'] = botttomRanges.one.outline_color;
			bottomValueLegend[0]['symbol_fill'] = botttomRanges.one.fill_color;
			bottomValueLegend[0]['vlabel'] = formattingNeeded
				? this.numberFormat(data - 1, formattingType) +
				  ' to ' +
				  this.numberFormat(botttomRanges.one.max, formattingType)
				: '' + (data - 1) + ' to ' + botttomRanges.one.max + '';
		}

		//Render config object
		if (bottomVal < 0 && topVal < 0) {
			cValues = cValues.concat(bottomValueLegend);
		} else if (bottomVal > 0 && topVal > 0) {
			cValues = cValues.concat(topValueLegend);
		} else {
			cValues = cValues.concat(bottomValueLegend);
			cValues = cValues.concat(topValueLegend);
		}
		return cValues;
	}

	numberFormat(number, formatType) {
		if (isNaN(Number(number))) {
			return number;
		} else {
			number = Number(number);
		}
		if (formatType === '%') {
			return number.toFixed(1) + ' %';
		} else if (formatType === '$') {
			return '$' + Number(number.toFixed(0)).toLocaleString();
		}
		return Number(number.toFixed(0)).toLocaleString();
	}
	createRender(view) {
		if (this.marketPosts) {
			let redereValues = this.getRenderConfig(view);
			if (redereValues !== 'NA') {
				let rendererf = [];
				let legendInfo = [];

				_.forEach(redereValues, data => {
					rendererf.push({
						minValue: parseInt(data.minvalue),
						maxValue: parseInt(data.maxvalue),
						symbol: new SimpleFillSymbol({
							type: 'simple-fill',
							color: data.symbol_fill,
							style: 'solid',
							outline: {
								color: data.symbol_colors,
								width: 1
							}
						}),
						label: data.vlabel
					});
					legendInfo.push({
						minValue: parseInt(data.minvalue),
						maxValue: parseInt(data.maxvalue),
						color: data.symbol_fill,
						label: data.vlabel
					});
				});
				this.legendData = legendInfo;
				let renderData = new ClassBreaksRenderer({
					field: 'Index',
					defaultLabel: 'no data',
					classBreakInfos: rendererf
				});
				let attributesValues = {};
				this.marketPosts.forEach(ele => {
					attributesValues = {
						...attributesValues,
						[ele.market]: {
							Index: ele[this.baseFact]
						}
					};
				});

				this.isMapDataReady = true;
				if (attributesValues && renderData) {
					this.updateMapLayer(attributesValues, renderData);
				}
			}
		} else {
			return this.showDefaultRenderer();
		}
	}

	showDefaultRenderer() {
		let renderer = new ClassBreaksRenderer({
			field: 'Index',
			defaultSymbol: new SimpleFillSymbol({
				color: '#F7F8F706',
				style: 'solid'
			}),
			defaultLabel: 'Not available',
			classBreakInfos: []
		});
		this.updateMapLayer({}, renderer);
	}
	updateMapLayer(attributesValues, renderData) {
		setTimeout(() => {
			this.gisMap.updateFeatureLayer(
				this.headerConfig.basefact.value.display,
				attributesValues,
				renderData
			);
		}, 10);
	}
	onMapClick($event: any) {
		this.selectedMarket = $event.event.results[0]?.graphic.attributes?.CMA_NAME;
		this.geographicDeepDiveCtrl.selectedMarket = [this.selectedMarket];
		this.requestPayload = { ...this.requestPayload, market: [this.selectedMarket] };
		this.selectedRegionValue = $event.event.results[0]?.graphic?.attributes?.Index;
		this.loadTablePost();
	}
	backToTotalUS() {
		this.selectedMarket = 'Total US';
		this.geographicDeepDiveCtrl.selectedMarket = [this.selectedMarket];
		this.requestPayload = { ...this.requestPayload, market: [this.selectedMarket] };
		this.gisMap.removeAllSelection();
		this.loadTablePost();
	}
	onMapHover($event: any) {
		let value = $event.event.results[0]?.graphic?.attributes?.Index;
		$event.mapView.openPopup({
			location: $event.event.results[0]?.graphic?.geometry.centroid,
			title: $event.event.results[0]?.graphic?.attributes?.CMA_NAME,
			content: `<p  style="font-size: 14px"><b>${
				this.headerConfig.basefact.value.display
			} : <span style="color:${
				value > 0 ? 'rgb(38, 185, 16)' : value === 0 ? 'black' : 'rgb(221, 0, 20)'
			}"> ${value}</span></b></p>`
		});
	}
}

import { Routes } from '@angular/router';
import { NlsnAdminLandingComponent } from './landing-page/landing-page.component';
import { ShareReportComponent } from './reports/share-report/share-report.component';
import { CategoryTrendsComponent } from './reports/category-trends/category-trends.component';
import { ProductPerformanceComponent } from './reports/product-performance/product-performance.component';
import { AssortmentDashboardComponent } from './reports/assortment-dashboard/assortment-dashboard.component';
import { GeographicDeepDiveComponent } from './reports/geographic-deep-dive/geographic-deep-dive.component';
import { WalmartPriceBeatsComponent } from './reports/walmart-price-beats/walmart-price-beats.component';
import { PanelDashboardComponent } from './reports/panel-dashboard/panel-dashboard.component';
import { PageComponent } from './page/page.component';
import { TrainingPageComponent } from './page/training-page/training-page.component';
import { PerformanceQuadrantReportPageComponent } from './page/performance-quadrant-report-page/performance-quadrant-report-page.component';
import { ConsumerDecisionTreePageComponent } from './page/consumer-decision-tree-page/consumer-decision-tree-page.component';
import { TrendedWeeklyComparisionPageComponent } from './page/trended-weekly-comparision-page/trended-weekly-comparision-page.component';
import { ConciergeHomepageComponent } from './concierge/components/concierge-homepage/concierge-homepage.component';
import { NlsnWMovtLandingService } from './core/service/auth/nlsn-admin.service';
import { ConciergeUserCreateComponent } from './concierge/components/concierge-user-create/concierge-user-create.component';
import { FavoritesPageComponent } from './landing-page/favorites-page/favorites-page.component';
import { RoleGuardService } from './core/guard/role-guard.service';
import { authGuard } from './core/guard/auth-guard.service';

export const routes: Routes = [
	{
		path: '',
		component: PageComponent,
		//canActivate: [authGuard()],
		canActivate: [NlsnWMovtLandingService],
		children: [
			{ path: '', component: NlsnAdminLandingComponent },
			{
				path: 'report',
				loadChildren: () => import('./features/report/report.module').then(m => m.ReportModule)
			},
			{ path: 'training', component: TrainingPageComponent },
			{
				path: 'quadrant',
				component: PerformanceQuadrantReportPageComponent
			},
			{ path: 'training', component: TrainingPageComponent },
			{ path: 'shareMulti', component: ShareReportComponent },

			{
				path: 'trended',
				component: TrendedWeeklyComparisionPageComponent
			},
			{
				path: 'kpi',
				component: CategoryTrendsComponent
			},
			{
				path: 'productPerformance',
				component: ProductPerformanceComponent
			},
			{
				path: 'assortment',
				component: AssortmentDashboardComponent
			},
			{
				path: 'geographicDeepDive',
				component: GeographicDeepDiveComponent
			},
			{ path: 'priceBeats', component: WalmartPriceBeatsComponent },
			{ path: 'panel', component: PanelDashboardComponent },
			{
				path: 'cdt',
				component: ConsumerDecisionTreePageComponent
			},
			{
				path: 'concierge',
				loadChildren: () => import('./concierge/concierge.module').then(m => m.ConciergeModule),
				canActivate: [RoleGuardService]
			},
			{
				path: 'leakage',
				loadChildren: () =>
					import('./page/leakage-tree-page/leakage-tree-page.module').then(
						m => m.LeakageTreePageModule
					)
			},
			{
				path: 'ranking',
				loadChildren: () =>
					import('./page/sales-driver-report/sales-driver-report.module').then(
						m => m.SalesDriverReportModule
					)
			},
			{ path: 'quadrant', component: PerformanceQuadrantReportPageComponent },
			{ path: 'training', component: TrainingPageComponent },
			{ path: 'shareMulti', component: ShareReportComponent },
			{ path: 'trended', component: TrendedWeeklyComparisionPageComponent },
			//{ path: 'trended', component: CategoryTrendsComponent },
			{ path: 'productPerformance', component: ProductPerformanceComponent },
			{ path: 'assortment', component: AssortmentDashboardComponent },
			{ path: 'geographicDeepDive', component: GeographicDeepDiveComponent },
			{ path: 'priceBeats', component: WalmartPriceBeatsComponent },
			{ path: 'panel', component: PanelDashboardComponent },
			{ path: 'cdt', component: ConsumerDecisionTreePageComponent },
			{ path: 'concierge.home', component: ConciergeHomepageComponent },
			{ path: 'concierge/createUsers', component: ConciergeUserCreateComponent },
			{ path: 'favorites', component: FavoritesPageComponent }
		]
	},
	// NOTE : Make sure these route need to place at end otherwise all routes go back to homepage
	// Wild card route added
	{
		path: '**',
		redirectTo: ''
	}
];

import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { ToastService } from '@nielseniq/athena-core';
import { Subject, takeUntil } from 'rxjs';
import { GridService } from 'src/app/shared/components/grid/grid-service/grid.service';
import { TOAST } from 'src/app/constants/toast.constant';
import { ConsumerDecisionTreeService } from 'src/app/service/consumer-decision-tree.service';
import { EXPORT_NAME } from 'src/app/constants/report.constant';

@Component({
	selector: 'crf-ca-consumer-decision-tree-upc-table',
	templateUrl: './consumer-decision-tree-upc-table.component.html',
	styleUrl: './consumer-decision-tree-upc-table.component.scss'
})
export class ConsumerDecisionTreeUpcTableComponent implements AfterViewInit {
	@Output() displayTreeViewEvent: EventEmitter<void> = new EventEmitter<void>();
	protected readonly unSubscribeAll: Subject<void> = new Subject<void>();
	table = {
		columnDefinition: [],
		data: [],
		title: '',
		loaded: false,
		sort: {
			column: 'WM $ Sales',
			order: 'desc'
		}
	};

	ui = {
		loaded: false
	};
	responseSortData = { headerName: '', sortOrder: '' };
	reportName = EXPORT_NAME.CDTCONSUMERDECISIONTREE;

	constructor(
		private service: ConsumerDecisionTreeService,
		private gridService: GridService,
		private toastService: ToastService
	) {}

	ngAfterViewInit(): void {
		this.gridService.sortColumnSubject$.subscribe({
			next: res => {
				this.responseSortData = res;
				if (res?.headerName && res?.sortOrder) {
					this.table.sort.column = res.headerName;
					this.table.sort.order = res.sortOrder;
				}
			}
		});
	}

	loadUpcTable(factDefinition: any, payload: any): void {
		//Resetting values
		this.table.data = [];
		this.table.title = '';

		this.table.loaded = false;
		this.ui.loaded = false;

		//Setting column definition
		this.table.columnDefinition = factDefinition;
		//Update sort config in col defination
		this.table.columnDefinition.filter((value, index) => {
			if (value.headerName === this.table.sort.column) {
				this.table.columnDefinition[index].sort = this.table.sort.order;
			}
		});

		//Setting title of the table
		this.table.title = payload.category;
		for (let i = 0; i <= 3; i++)
			if (payload['lvl' + i]) this.table.title = this.table.title + ' | ' + payload['lvl' + i];
			else break;

		//Making API call to fetch data from backend
		this.service
			.getUpcTableData(payload)
			?.pipe(takeUntil(this.unSubscribeAll))
			.subscribe({
				next: res => {
					if (res.responseData) {
						this.table.data = res.responseData.products;
						this.table.loaded = this.ui.loaded = true;
					} else
						this.toast(
							'warning',
							'Consumer Decision Tree UPC Table',
							'Load UPC Table: Invalid Response'
						);
				},
				error: err => {
					this.table.loaded = this.ui.loaded = true;
					this.toast(
						'error',
						'Consumer Decision Tree UPC Table',
						'Load UPC Table: Invalid Response'
					);
				}
			});
	}

	updateColumnDefinition(facts: any) {
		this.ui.loaded = false;
		this.table.columnDefinition = facts;
		setTimeout(() => (this.ui.loaded = true), 10);
	}

	clearData(): void {
		this.table.data = [];
	}

	displayTreeView(): void {
		this.displayTreeViewEvent.emit();
	}

	toast(type: any, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}

	ngOnDestroy(): void {
		this.unSubscribeAll.next();
		this.unSubscribeAll.complete();
	}
}

import { Component, ViewChild } from '@angular/core';
import { NlsnDataStoreService } from '../../service/store/nlsn-data-store.service';
import { KEY } from '../../../constants/data.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TopbarService } from 'src/app/core/service/topbar/topbar.service';
import { DialogComponent, ToastService } from '@nielseniq/athena-core';
import { Router } from '@angular/router';
import { FeedbackDropdownOption, NotificationMessage } from '../../model/topbar.model';
import { TopbarUtilityService } from '../../service/topbar/topbar-utility.service';

@Component({
	selector: 'crf-ca-topbar',
	templateUrl: './topbar.component.html',
	styleUrl: './topbar.component.scss'
})
export class TopbarComponent {
	@ViewChild('notificationDialog') notificationDialog: DialogComponent;

	userDetails: any;
	firstName: any;

	header = {
		bannerTitleText: 'Walmart One Version of Truth',
		currentUserName: 'Login User'
	};

	//PANEL
	isAppMenuPanelVisible = false;
	isNotificationPanelVisible = false;
	isProfileMenuVisible = false;

	//FEEDBACK
	feedbackFormGroup: FormGroup;
	feedbackFormReportDropdownOptions: FeedbackDropdownOption[] = [];
	isFeedbackFormSubmittedAndInvalid: boolean = false;

	//NOTIFICATION
	notifications: NotificationMessage[] = [];
	unreadNotificationCount: number = 0;

	constructor(
		private nlsnDataStoreService: NlsnDataStoreService,
		private formBuilder: FormBuilder,
		private topbarService: TopbarService,
		private topbarUtility: TopbarUtilityService, //DON'T REMOVE THIS (IMPACT IN REPORT PAGE TITLE)
		private toastService: ToastService,
		private router: Router
	) {
		//Setting up the feedback form in topbar
		this.feedbackFormGroup = this.formBuilder.group({
			dashboardIndex: ['0', Validators.required],
			feedback: ['', Validators.required]
		});
	}

	ngOnInit(): void {
		this.userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		this.getHomePageTiles();

		//Loading notifications
		this.getAllNotifications();
	}

	getHomePageTiles() {
		this.header.currentUserName = this.userDetails?.userinfo?.name;
		this.firstName = this.userDetails?.userinfo?.given_name;
	}

	onLogout() {
		this.topbarService.logout();
	}

	toggleNotifications(): void {
		this.isNotificationPanelVisible = !this.isNotificationPanelVisible;

		if (this.isNotificationPanelVisible) this.getAllNotifications();
	}

	//NOTIFICATION
	getAllNotifications(): void {
		this.topbarService.readAllNotifications().subscribe(response => {
			if (response.result) {
				this.notifications = response.result
					.sort((notification1, notification2) => {
						if (notification1.message.msgId < notification2.message.msgId) return 1;
						else if (notification1.message.msgId > notification2.message.msgId) return -1;
						else return 0;
					})
					.map(notification => {
						//Calculating the number of unread message to set notification badge
						if (!notification.isViewed) this.unreadNotificationCount++;

						return {
							id: notification.id,
							messageId: notification.message.msgId,
							date: notification.message.msgDate,
							heading: notification.message.msgHeader,
							subHeading: notification.message.msgSubheader,
							content: notification.message.msgContent,
							contactInfo: notification.message.msgContactInfo,
							isViewed: notification.isViewed
						};
					});
			}
		});
	}

	setNotificationAsRead(notificationId): void {
		this.isNotificationPanelVisible = false;
		this.unreadNotificationCount = 0;

		this.notificationDialog.open();
		this.topbarService.updateNotificationAsRead(notificationId).subscribe(response => {
			if (response.responseCode === 'SUCCESS')
				if (this.unreadNotificationCount > 0) this.unreadNotificationCount--;
		});
	}

	navigate() {
		this.toastService.DismissToast();
		this.router.navigate(['']);
		this.toastService.DismissToast();
	}
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CategoryTrendsApiService } from './service/category-trends-api.service';
import { pageHoverTexts } from 'src/app/models/report-hovertexts.constant';
import { SELECTOR } from 'src/app/shared/constants/selector.constant';
import { ReportService } from 'src/app/service/report.service';
import { Subject, map, takeUntil } from 'rxjs';
import {
	BY_LEVEL_DISPLAY_LABEL,
	BY_LEVEL_REQUEST_TYPE,
	EXPORT_NAME
} from 'src/app/constants/report.constant';
import * as _ from 'lodash';
import { GEOGRAPHIC_DEEP_DIVE_DASHBOARD } from 'src/app/constants/dashboard-ui.constant';
import {
	DOLLARVOLUMMETRICS,
	EQUNITVOLUMMETRICS,
	SHARE,
	UNITVOLUMMETRICS
} from './category-trends.model';
import { ToastService } from '@nielseniq/athena-core';
import { TOAST } from 'src/app/constants/toast.constant';
import { UtilityService } from '../services/utility.service';

import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol.js';
import { SaveDefaultComponent } from 'src/app/shared/components/selector/save-default/save-default.component';
import { GisMapComponent } from 'src/app/shared/components/gis-map/gis-map.component';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import { TopbarUtilityService } from 'src/app/core/service/topbar/topbar-utility.service';
import { ReportDimension } from 'src/app/models/report-dimension.model';

@Component({
	selector: 'crf-ca-category-trends',
	templateUrl: './category-trends.component.html',
	styleUrl: './category-trends.component.scss'
})
export class CategoryTrendsComponent implements OnInit, OnDestroy {
	activeTab = 'category';
	brandOwnerList: any;
	parentCompanyList: any;
	defaultBrandOwner: any;
	defaultParentCompany: any;
	DOL_SHRPT_CHG_STR = 'WM $ Share Pt Chg (BPS)';
	NOT_AVAILABLE = 'Not available';
	isMapDataReady = true;
	isPPTClick = false;
	showMenu = false;
	reportName = EXPORT_NAME.GEOGRAPHYPERFORMANCE;

	@ViewChild(SaveDefaultComponent) saveDefaultComponent: SaveDefaultComponent;
	@ViewChild(GisMapComponent) gisMap: GisMapComponent;

	@ViewChild('map1', { static: true }) ele!: ElementRef<HTMLImageElement>;
	private destroySubject: Subject<void> = new Subject();
	levelDisplayName = GEOGRAPHIC_DEEP_DIVE_DASHBOARD.HEADER_NAMES;
	levelDisplayNameByKey = BY_LEVEL_DISPLAY_LABEL;
	defaultPayload: any;
	byLevelDropdown = [
		{
			key: 'BRAND_OWNER_HIGH',
			value: 'Brand Owner High',
			isSelected: false
		},
		{
			key: 'BRAND_OWNER_LOW',
			value: 'Brand Owner',
			isSelected: false
		}
	];
	headerConfig = {
		product: {
			value: {
				display: '',
				selectedProduct: []
			},
			ui: {
				display: false,
				disabled: false
			},
			config: {
				id: SELECTOR.PRODUCT.ID,
				multiSelect: true,
				apiFetched: true,
				available: true
			}
		},
		bylevel: {
			value: {
				display: '',
				dropdown: this.byLevelDropdown
			},
			ui: {
				display: false,
				disabled: false
			},
			config: {
				id: SELECTOR.BYLEVEL.ID,
				multiSelect: false,
				apiFetched: true,
				available: true
			}
		},
		market: {
			value: {
				display: [],
				dropdown: [],
				values: ''
			},
			ui: {
				display: false,
				disabled: false
			},
			config: {
				id: SELECTOR.MARKET.ID,
				multiSelect: false,
				apiFetched: false,
				available: false
			}
		},
		period: {
			value: {
				display: '',
				initial: [],
				actual: [],
				dropdown: []
			},
			ui: {
				display: false,
				disabled: false
			},
			config: {
				id: SELECTOR.PERIOD.ID,
				multiSelect: false,
				apiFetched: false,
				available: false
			}
		}
	};
	selectedMarket = 'Total US';
	questionId: number = 1;
	pageHoverTexts: any = pageHoverTexts;
	share: SHARE;
	dollarVolumetrics: DOLLARVOLUMMETRICS[];
	unitVolumetrics: UNITVOLUMMETRICS[];
	eqUnitsVolumetrics: EQUNITVOLUMMETRICS[];
	requestPayload: any;
	lastLevel: string;
	productLevel = BY_LEVEL_REQUEST_TYPE;
	periodData: any;
	legendData: any;
	byprodlevel: string;
	isLoading: boolean;
	marketPost: { market: string; sharePTChgByCat: string; sharePTChgByMF: any }[];
	noData: boolean;
	pageTitle: any;
	kpiCtrl: ReportDimension = {
		dashboardName: 'Category Product Comparison (Scan)',
		selectedMarket: ['Total US']
	};
	defaultParentCompanyTitle: any;
	defaultBrandOwnerTitle: any;

	constructor(
		private readonly requestService: CategoryTrendsApiService,
		private readonly reportService: ReportService,
		private readonly toastService: ToastService,
		private readonly utilityService: UtilityService,
		private topBarUtility: TopbarUtilityService
	) {}

	ngOnInit() {
		this.pageTitle = this.topBarUtility.getPageTitleReport();
		this.getDefaultSelectors();
	}
	getDefaultSelectors() {
		this.isLoading = true;
		this.reportService
			.getSelectors(this.questionId)
			?.pipe(takeUntil(this.destroySubject))
			.subscribe(response => {
				this.resetAll();
				this.createRequestPayload(response.responseData);
			});
	}
	createRequestPayload(request) {
		this.defaultPayload = request;
		let selectedProduct = [];
		let tempProduct = {
			department: request.department ? request.department.split(';') : [],
			categoryGroup: request.categorygroup ? request.categorygroup.split(';') : [],
			category: request.category ? request.category.split(';') : [],
			totalBusiness: request.totalbusiness ? request.totalbusiness.split(';') : [],
			majorBusiness: request.majorbusiness ? request.majorbusiness.split(';') : [],
			market: [this.selectedMarket]
		};
		// Set default value with
		this.requestPayload = { ...tempProduct };
		Object.keys(this.productLevel).forEach(element => {
			if (!_.isEmpty(tempProduct[element])) {
				selectedProduct.push({ [element]: tempProduct[element] });
				this.lastLevel = element;
			}
		});
		this.productFooterView(selectedProduct);
		this.headerConfig.product.value.display = selectedProduct.slice(-1)[0][this.lastLevel];
		this.setByLevel(request.byLevel);
		this.loadPeriods();
	}
	setByLevel(defaultByLevel) {
		let level;
		level = this.byLevelDropdown[0];
		let index = this.headerConfig.bylevel.value.dropdown.findIndex(x => x.value === level.value);
		this.headerConfig.bylevel.value.dropdown[index].isSelected = true;
		this.requestPayload = { ...this.requestPayload, byprodlevel: level.key };
		this.byprodlevel = level.key;
		this.headerConfig.bylevel.value.display = level.value;
		this.kpiCtrl.selectedLevelToShow = level.value;
	}
	loadMarket(product) {
		this.isLoading = true;
		let payload = {
			department: product.department,
			categorygroup: product.categoryGroup,
			category: product.category,
			totalBusiness: product.totalBusiness,
			majorBusiness: product.majorBusiness
		};
		this.reportService
			.getParentCompany(this.objectToQueryParams(payload))
			.pipe(takeUntil(this.destroySubject))
			.subscribe(response => {
				this.defaultBrandOwner = [response.defaultBrandOwner];
				this.defaultParentCompany = [response.defaultParentCompany];
				this.defaultParentCompanyTitle = this.defaultParentCompany.toString();
				this.defaultBrandOwnerTitle = this.defaultBrandOwner.toString();

				this.brandOwnerList = response?.brandOwnerList?.map((data, index) => ({
					value: data,
					id: index + 1,
					isSelected: data === response.defaultBrandOwner ? true : false
				}));
				this.parentCompanyList = response?.parentCompanyList?.map((data, index) => ({
					value: data,
					id: index + 1,
					isSelected: data === response.defaultParentCompany ? true : false
				}));
				this.headerConfig.market.config.available = true;
				this.kpiCtrl.selectedLevelToShow =
					this.headerConfig.bylevel.value.display +
					': ' +
					(this.byprodlevel === 'BRAND_OWNER_HIGH'
						? this.defaultParentCompany
						: this.defaultBrandOwner);
				this.setMarket();
				this.loadTablePost();
				this.loadMarketPost();
			});
	}

	setMarket() {
		let values =
			this.byprodlevel === 'BRAND_OWNER_HIGH' ? this.defaultParentCompany : this.defaultBrandOwner;
		this.requestPayload = { ...this.requestPayload, brandOwner: values };
	}
	objectToQueryParams(obj) {
		const params = [];
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key].join(';');
				params.push(`${key}=${encodeURIComponent(value)}`);
			}
		}
		return params.join('&');
	}
	loadPeriods() {
		this.reportService
			.getPeriod()
			.pipe(
				map(period => period.responseData),
				takeUntil(this.destroySubject)
			)
			.subscribe(response => {
				if (!_.isEmpty(response)) {
					this.periodData = response;
					Object.keys(this.periodData).forEach(item => {
						if (!_.isEmpty(this.defaultPayload.periodIds)) {
							if (this.defaultPayload.periodIds === item) {
								this.setPeriodData(item);
							}
						} else if (this.periodData[item].defaultPeriod) {
							this.setPeriodData(item);
						}
						this.headerConfig.period.value.dropdown.push({
							key: this.periodData[item].id,
							value: item,
							isSelected: false
						});
					});
					this.requestPayload = {
						...this.requestPayload,
						period: this.periodData.default_key,
						cyPeriod: this.periodData.cyPeriod,
						yaPeriod: this.periodData.yaPeriod
					};
					let index = this.headerConfig.period.value.dropdown.findIndex(
						x => x.value === this.periodData.default_key
					);
					this.headerConfig.period.value.dropdown[index].isSelected = true;
				}
				// Only for first load
				this.loadMarket(this.requestPayload);

				this.getPeriodEndDate();
			});
	}
	setPeriodData(item) {
		this.headerConfig.period.config.available = true;
		this.headerConfig.period.value.display = item;
		this.kpiCtrl.period = item;
		this.periodData.default_key = item;
		this.periodData.cyPeriod = this.periodData[item].currentPeriod;
		this.periodData.yaPeriod = this.periodData[item].previousPeriod;
		this.periodData.id = this.periodData[item].id;
	}
	getPeriodEndDate() {
		// Remove unused keys-> valuea
		let periodEndPayload = _.omit(this.requestPayload, [
			'rmMarket',
			'wmMarket',
			'cyPeriod',
			'yaPeriod',
			'twoYAPeriod'
		]);
		this.reportService
			.getPeriodEndDate(this.questionId, this.periodData.id, periodEndPayload)
			.pipe(takeUntil(this.destroySubject))
			.subscribe(res => {
				this.kpiCtrl.periodEndDate = res.periodEndDate;
			});
	}
	resetAll() {
		this.headerConfig = {
			product: {
				value: {
					display: '',
					selectedProduct: []
				},
				ui: {
					display: false,
					disabled: false
				},
				config: {
					id: SELECTOR.PRODUCT.ID,
					multiSelect: true,
					apiFetched: true,
					available: true
				}
			},
			bylevel: {
				value: {
					display: '',
					dropdown: this.byLevelDropdown
				},
				ui: {
					display: false,
					disabled: false
				},
				config: {
					id: SELECTOR.BYLEVEL.ID,
					multiSelect: false,
					apiFetched: true,
					available: true
				}
			},
			market: {
				value: {
					display: [],
					dropdown: [],
					values: ''
				},
				ui: {
					display: false,
					disabled: false
				},
				config: {
					id: SELECTOR.MARKET.ID,
					multiSelect: false,
					apiFetched: false,
					available: false
				}
			},
			period: {
				value: {
					display: '',
					initial: [],
					actual: [],
					dropdown: []
				},
				ui: {
					display: false,
					disabled: false
				},
				config: {
					id: SELECTOR.PERIOD.ID,
					multiSelect: false,
					apiFetched: false,
					available: false
				}
			}
		};
	}
	valueChangeHandler($event: any, key: string) {
		switch (key) {
			case SELECTOR.PRODUCT.ID: {
				// Remove unused keys-> valuea
				let productSelection = _.pick($event, [
					'totalBusiness',
					'majorBusiness',
					'department',
					'categoryGroup',
					'category'
				]);
				this.requestPayload = { ...this.requestPayload, ...productSelection };
				let products = [];
				Object.keys(productSelection).forEach(element => {
					if (!_.isEmpty(productSelection[element])) {
						products.push({ [element]: productSelection[element] });
						this.lastLevel = element;
					}
				});
				this.headerConfig.product.value.display = products?.slice(-1)[0][this.lastLevel];
				this.productFooterView(products);
				this.loadMarket(productSelection);
				break;
			}
			case SELECTOR.BYLEVEL.ID:
				this.byprodlevel = $event.key;
				this.headerConfig.bylevel.value.display = $event.value;
				this.kpiCtrl.selectedLevelToShow =
					$event.value +
					': ' +
					(this.byprodlevel === 'BRAND_OWNER_HIGH'
						? this.defaultParentCompany
						: this.defaultBrandOwner);
				// update payload
				this.setMarket();
				this.requestPayload = {
					...this.requestPayload,
					byprodlevel: this.byprodlevel
				};
				break;
			case SELECTOR.MARKET.ID:
				if (this.byprodlevel === 'BRAND_OWNER_HIGH') {
					this.defaultParentCompany = $event;
					this.defaultParentCompanyTitle = this.defaultParentCompany.toString();
					this.kpiCtrl.selectedLevelToShow =
						this.headerConfig.bylevel.value.display + ': ' + this.defaultParentCompany;
				} else {
					this.defaultBrandOwner = $event;
					this.defaultBrandOwnerTitle = this.defaultBrandOwner.toString();
					this.kpiCtrl.selectedLevelToShow =
						this.headerConfig.bylevel.value.display + ': ' + this.defaultBrandOwner;
				}
				// update payload
				this.requestPayload = {
					...this.requestPayload,
					brandOwner: $event
				};
				break;
			case SELECTOR.PERIOD.ID: {
				let item = $event.value;
				// display header value
				this.headerConfig.period.value.display = item;
				this.kpiCtrl.period = item;
				this.periodData.default_key = item;
				this.periodData.cyPeriod = this.periodData[item].currentPeriod;
				this.periodData.yaPeriod = this.periodData[item].previousPeriod;
				this.periodData.id = this.periodData[item].id;
				// update payload
				this.requestPayload = {
					...this.requestPayload,
					period: item,
					cyPeriod: this.periodData.cyPeriod,
					yaPeriod: this.periodData.yaPeriod
				};
				break;
			}
			default:
				break;
		}
		this.closeAllDropdown();
		if (key !== SELECTOR.PRODUCT.ID) {
			this.loadTablePost();
			this.loadMarketPost();
		}
	}
	toggleDropdown(selectorId: string): void {
		Object.keys(this.headerConfig).forEach(selectorKey => {
			if (selectorKey !== selectorId) this.headerConfig[selectorKey].ui.display = false;
			else this.headerConfig[selectorKey].ui.display = !this.headerConfig[selectorKey].ui.display;
		});
	}
	closeAllDropdown(): void {
		Object.keys(this.headerConfig).forEach(
			selectorKey => (this.headerConfig[selectorKey].ui.display = false)
		);
		this.saveDefaultComponent.showMoreOptions = false;
	}
	saveResetDefault(event: any, type: string) {
		let payload: any = {};
		if (event.menuId == 1) {
			//Save defaults
			switch (type) {
				case 'all':
					Object.keys(this.productLevel).forEach(key => {
						payload = { ...payload, [key]: this.convertArrayToString(this.requestPayload[key]) };
					});
					payload = {
						...payload,
						byLevel: '',
						periodIds: this.headerConfig.period.value.display
					};
					break;
				case 'periodIds':
					payload = { periodIds: this.headerConfig.period.value.display };
					this.defaultPayload.periodIds = payload.periodIds;
					break;
			}
			this.reportService
				.saveSelectors(this.questionId, type, payload)
				.pipe(takeUntil(this.destroySubject))
				.subscribe({
					next: res => {
						this.toastService.InjectToast(
							'success',
							'Selections saved successfully',
							'',
							TOAST.TIMEOUT,
							'',
							'Success',
							TOAST.SIZE
						);
					},
					error: err => console.log(err)
				});
		} else if (event.menuId == 2) {
			switch (type) {
				case 'all':
					this.getDefaultSelectors();
					break;
				case 'periodIds': {
					Object.keys(this.periodData).forEach(item => {
						if (!_.isEmpty(this.defaultPayload.periodIds)) {
							if (this.defaultPayload.periodIds === item) {
								this.setPeriodData(item);
							}
						} else if (this.periodData[item].defaultPeriod) {
							this.setPeriodData(item);
						}
					});
					let config = {
						key: this.headerConfig.period.value.display.split(' ')[1],
						value: this.headerConfig.period.value.display
					};
					let index = this.headerConfig.period.value.dropdown.findIndex(
						x => x.value === this.periodData.default_key
					);
					this.headerConfig.period.value.dropdown.forEach((ele, idx) => {
						ele.isSelected = false;
						if (index === idx) {
							ele.isSelected = true;
						}
					});
					this.valueChangeHandler(config, 'period');
					this.utilityService.preselectDefault(
						this.headerConfig.period.value.dropdown,
						this.defaultPayload.periodIds
					);
					break;
				}
			}
		}
		this.closeAllDropdown();
	}
	// convert [A,B,C] ==> A;B;C
	convertArrayToString(requestedArray) {
		if (requestedArray) {
			return requestedArray?.toString().replaceAll(',', ';');
		}
	}
	loadTablePost(isTotalMarket = true) {
		this.isLoading = true;
		this.requestService
			.getTablePost(this.requestPayload, `isTotalMarket=${isTotalMarket}`)
			.pipe(takeUntil(this.destroySubject))
			.subscribe(res => {
				this.share = res?.share;
				this.dollarVolumetrics = res?.dollarVolumetrics;
				this.unitVolumetrics = res?.unitVolumetrics;
				this.eqUnitsVolumetrics = res?.eqUnitVolumetrics;
				this.isLoading = false;
			});
	}
	loadMarketPost(isTotalMarket = true) {
		this.isMapDataReady = false;

		this.isLoading = true;
		this.requestService
			.getMarketPost(this.requestPayload, `isTotalMarket=${isTotalMarket}`)
			.pipe(takeUntil(this.destroySubject))
			.subscribe(res => {
				this.marketPost = res;
				if (_.isEmpty(res)) {
					this.noData = true;
				}
				this.createRender(this.activeTab);
				this.isLoading = false;
			});
	}
	/**
	 *
	 * @param products
	 * product=[
	 * {filter:"A",values:[a,b,c]}
	 * ]
	 */
	productFooterView(products) {
		let footerLevelViewData = [];
		products.forEach(element => {
			footerLevelViewData.push({
				['filter']: this.levelDisplayName[this.productLevel[Object.keys(element)[0]]],
				['value']: Object.values(element)
			});
		});
		this.headerConfig.product.value.selectedProduct = footerLevelViewData;
		this.kpiCtrl.selectedProduct = this.reportService.setSelectedProduct(
			this.headerConfig.product.value.selectedProduct,
			true
		);
	}

	onTabChange(tab: string) {
		switch (tab) {
			case 'category':
				this.activeTab = tab;
				break;
			case 'company':
				this.activeTab = tab;
				break;
			default:
				this.activeTab = 'category';
				break;
		}
		this.createRender(this.activeTab);
	}
	ngOnDestroy(): void {
		// Unsubscribe from all observables
		this.destroySubject.next();
		this.destroySubject.complete();
	}
	reportDownload(type) {
		let exportPayload = {
			...this.requestPayload,
			applicationType: 'JBP',
			dataModelType: 'GEOGRAPHYPERFORMANCE',
			level: this.headerConfig.bylevel.value.display,
			periodEndDate: this.kpiCtrl.periodEndDate,
			bylevel: `${this.headerConfig.bylevel.value.display}: ${
				this.byprodlevel === 'BRAND_OWNER_HIGH' ? this.defaultParentCompany : this.defaultBrandOwner
			}`,
			scope: this.lastLevel,
			selectedVal: this.headerConfig.product.value.display,
			isTotalMarket: this.selectedMarket === 'Total US' ? true : false
		};
		if (type === 'ppt') {
			exportPayload = {
				...exportPayload,
				period: parseInt(this.headerConfig.period.value.display.split(' ')[1]),
				marketName: this.selectedMarket,
				imageBase64Map1: '',
				imageBase64Map2: '',
				selectedValue: this.headerConfig.product.value.display.toString(),
				scopeDisplayName: BY_LEVEL_DISPLAY_LABEL[this.lastLevel]
			};
			// Temp change
			this.gisMap.takeScreenshot().then(base64Image => {
				exportPayload.legendData1 = this.legendData;
				let isCategory = this.activeTab === 'category';
				let view = isCategory ? 'company' : 'category';
				this.onTabChange(view);
				this.isPPTClick = true;
				this.gisMap.whenPromise().then(() => {
					this.gisMap.takeScreenshot().then(base64Image2 => {
						if (isCategory) {
							exportPayload.imageBase64Map1 = base64Image;
							exportPayload.imageBase64Map2 = base64Image2;
						} else {
							exportPayload.imageBase64Map2 = base64Image;
							exportPayload.imageBase64Map1 = base64Image2;
						}
						exportPayload.legendData2 = this.legendData;
						this.utilityService.exportFile(exportPayload, type);
						this.isPPTClick = false;
						setTimeout(() => {
							let initialView = isCategory ? 'category' : 'company';
							this.onTabChange(initialView);
						}, 5000);
					});
				});
			});
		} else {
			this.utilityService.exportFile(exportPayload, type);
		}
		this.closeAllDropdown();
	}

	getRenderConfig(view) {
		//Dyanamic ranges
		let notAvilableFlag = false;
		let topValueLegend = [],
			bottomValueLegend = [];
		let cValues = [];
		let topVal, bottomVal;
		let topRagediff;
		let bottomRangediff;
		let maxValue = _.maxBy(this.marketPost, o => {
			if (view === 'category') {
				if (_.isNaN(parseInt(o.sharePTChgByCat))) {
					notAvilableFlag = true;
				}
				return parseInt(o.sharePTChgByCat);
			} else {
				if (_.isNaN(parseInt(o.sharePTChgByMF))) {
					notAvilableFlag = true;
				}
				return parseInt(o.sharePTChgByMF);
			}
		});
		let minValue = _.minBy(this.marketPost, o => {
			if (view === 'category') {
				return parseInt(o.sharePTChgByCat);
			} else {
				return parseInt(o.sharePTChgByMF);
			}
		});

		if (!maxValue && !minValue) {
			return 'NA';
		}

		//Dividing ranges into 6 sections top 3 and bottom 3
		if (view === 'category') {
			topVal = maxValue.sharePTChgByCat;
			bottomVal = minValue.sharePTChgByCat;
			topRagediff = topVal < 3 ? topVal : _.floor(maxValue.sharePTChgByCat / 3);
			bottomRangediff = bottomVal > -3 ? bottomVal : _.floor(minValue.sharePTChgByCat / 3);
		} else {
			topVal = maxValue.sharePTChgByMF;
			bottomVal = minValue.sharePTChgByMF;
			topRagediff = topVal < 3 ? topVal : _.floor(maxValue.sharePTChgByMF / 3);
			bottomRangediff = bottomVal > -3 ? bottomVal : _.floor(minValue.sharePTChgByMF / 3);
		}

		let topRanges = {
			one: {
				max: topRagediff * 1,
				min: 0,
				label: '' + 0 + ' to ' + topRagediff * 1 + '',
				fill_color: [151, 219, 151, 1],
				outline_color: [128, 193, 128, 1]
			},
			two: {
				max: topRagediff * 2,
				min: topRagediff * 1,
				label: '' + topRagediff * 1 + ' to ' + topRagediff * 2 + '',
				fill_color: [93, 180, 93, 1],
				outline_color: [75, 154, 75, 1]
			},
			three: {
				max: topVal,
				min: topRagediff * 2,
				label: '' + topRagediff * 2 + ' to ' + topVal + '',
				fill_color: [38, 185, 16, 1],
				outline_color: [30, 154, 11, 1]
			}
		};

		let botttomRanges = {
			one: {
				max: 0,
				min: bottomRangediff * 1,
				label: '' + bottomRangediff * 1 + ' to ' + 0 + '',
				fill_color: [255, 204, 204, 1],
				outline_color: [230, 177, 177, 1]
			},
			two: {
				max: bottomRangediff * 1,
				min: bottomRangediff * 2,
				label: '' + bottomRangediff * 2 + ' to ' + bottomRangediff * 1 + '',
				fill_color: [213, 88, 88, 1],
				outline_color: [162, 77, 77, 1]
			},
			three: {
				max: bottomRangediff * 2,
				min: bottomVal,
				label: '' + (bottomVal - 1) + ' to ' + bottomRangediff * 2 + '',
				fill_color: [224, 0, 32, 1],
				outline_color: [145, 19, 37, 1]
			}
		};

		bottomValueLegend = [
			{
				minvalue: botttomRanges.three.min,
				maxvalue: botttomRanges.three.max,
				symbol_colors: botttomRanges.three.outline_color,
				symbol_fill: botttomRanges.three.fill_color,
				vlabel: botttomRanges.three.label
			},
			{
				minvalue: botttomRanges.two.min,
				maxvalue: botttomRanges.two.max,
				symbol_colors: botttomRanges.two.outline_color,
				symbol_fill: botttomRanges.two.fill_color,
				vlabel: botttomRanges.two.label
			},
			{
				minvalue: botttomRanges.one.min,
				maxvalue: botttomRanges.one.max,
				symbol_colors: botttomRanges.one.outline_color,
				symbol_fill: botttomRanges.one.fill_color,
				vlabel: botttomRanges.one.label
			}
		];

		topValueLegend = [
			{
				minvalue: topRanges.one.min,
				maxvalue: topRanges.one.max,
				symbol_colors: topRanges.one.outline_color,
				symbol_fill: topRanges.one.fill_color,
				vlabel: topRanges.one.label
			},
			{
				minvalue: topRanges.two.min,
				maxvalue: topRanges.two.max,
				symbol_colors: topRanges.two.outline_color,
				symbol_fill: topRanges.two.fill_color,
				vlabel: topRanges.two.label
			},
			{
				minvalue: topRanges.three.min,
				maxvalue: topRanges.three.max,
				symbol_colors: topRanges.three.outline_color,
				symbol_fill: topRanges.three.fill_color,
				vlabel: topRanges.three.label
			}
		];

		if (topVal < 3) {
			topValueLegend.splice(1, 2);
		}

		if (bottomVal > -3) {
			if (bottomVal !== '0') {
				bottomValueLegend.splice(0, 2);
			} else {
				bottomValueLegend.splice(0, 3);
			}
		}

		//Render config object
		if (bottomVal < 0 && topVal < 0) {
			cValues = cValues.concat(bottomValueLegend);
		} else if (bottomVal > 0 && topVal > 0) {
			cValues = cValues.concat(topValueLegend);
		} else {
			cValues = cValues.concat(bottomValueLegend);
			cValues = cValues.concat(topValueLegend);
		}
		return cValues;
	}
	createRender(view) {
		let redereValues = this.getRenderConfig(view);
		let rendererf = [];
		let legendInfo = [];
		if (redereValues !== 'NA') {
			_.forEach(redereValues, data => {
				rendererf.push({
					minValue: parseInt(data.minvalue),
					maxValue: parseInt(data.maxvalue),
					symbol: new SimpleFillSymbol({
						type: 'simple-fill',
						color: data.symbol_fill,
						style: 'solid',
						outline: {
							color: data.symbol_colors,
							width: 1
						}
					}),
					label: data.vlabel
				});
				legendInfo.push({
					minValue: parseInt(data.minvalue),
					maxValue: parseInt(data.maxvalue),
					color: data.symbol_fill,
					label: data.vlabel
				});
			});
			let renderData = new ClassBreaksRenderer({
				field: 'Index',
				defaultSymbol: new SimpleFillSymbol({
					color: '#F7F8F750',
					style: 'solid',
					outline: { width: 0.2, color: '#000000A0' }
				}),
				defaultLabel: 'Not available',
				classBreakInfos: rendererf
			});
			let attributesValues = {};
			this.marketPost.forEach(ele => {
				let value;
				if (this.activeTab === 'category') {
					value = ele.sharePTChgByCat === null ? {} : { index: ele.sharePTChgByCat };
				} else {
					value = ele.sharePTChgByMF === null ? {} : { index: ele.sharePTChgByMF };
				}
				attributesValues = {
					...attributesValues,
					[ele.market]: value
				};
			});

			this.isMapDataReady = true;
			if (attributesValues && renderData) {
				this.updateMapLayer(attributesValues, renderData);
			}
		} else {
			this.showDefaultRenderer();
		}
		this.legendData = legendInfo;
	}

	showDefaultRenderer() {
		let renderer = new ClassBreaksRenderer({
			field: 'Index',
			defaultSymbol: new SimpleFillSymbol({
				color: '#F7F8F706',
				style: 'solid'
			}),
			defaultLabel: 'Not available',
			classBreakInfos: []
		});
		this.updateMapLayer({}, renderer);
	}
	hideNoDataMessage() {
		this.noData = false;
	}
	updateMapLayer(attributesValues, renderData) {
		setTimeout(() => {
			this.gisMap.updateFeatureLayer('WM $ Share Pt Chg (BPS)', attributesValues, renderData);
		}, 10);
	}
	onMapClick($event: any) {
		this.selectedMarket = $event.event.results[0]?.graphic.attributes?.CMA_NAME;
		this.requestPayload = { ...this.requestPayload, market: [this.selectedMarket] };
		this.kpiCtrl.selectedMarket = [this.selectedMarket];
		this.loadTablePost(false);
	}
	backToTotalUS() {
		this.selectedMarket = 'Total US';
		this.requestPayload = { ...this.requestPayload, market: [this.selectedMarket] };
		this.kpiCtrl.selectedMarket = [this.selectedMarket];
		this.gisMap.removeAllSelection();
		this.loadTablePost(true);
	}
	onMapHover($event: any) {
		let value = $event.event.results[0]?.graphic?.attributes?.Index;
		$event.mapView.openPopup({
			location: $event.event.results[0]?.graphic?.geometry.centroid,
			title: $event.event.results[0]?.graphic?.attributes?.CMA_NAME,
			content: `<p  style="font-size: 14px"><b>WM $ Share Pt Chg (BPS) : <span style="color:${
				value > 0 ? 'rgb(38, 185, 16)' : value === 0 ? 'black' : 'rgb(221, 0, 20)'
			}"> ${value}</span></b></p>`
		});
	}
}

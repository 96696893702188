import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter',
	standalone: true
})
export class FilterPipe implements PipeTransform {
	transform(items: any[], searchText: string, fieldName: string): any[] {
		if (!items) {
			return [];
		}
		if (!searchText) {
			return items;
		}

		searchText = searchText?.toLowerCase();

		let filterItems = items.filter(item => item[fieldName]?.toLowerCase().includes(searchText));

		return items
			.filter(item => item[fieldName]?.toLowerCase().includes(searchText))
			.map(item => {
				item.highlightedValue = this.highlightSearchText(item[fieldName], searchText);
				return item;
			});
	}

	highlightSearchText(item: string, searchText: string): string {
		// Escape special characters in the search text for use in a regular expression
		const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		const regex = new RegExp(escapedSearchText, 'gi'); // Global, case-insensitive

		// Use the escaped regex to replace matches with highlighted text
		return item?.replace(regex, match => `<b>${match}</b>`);
	}
}

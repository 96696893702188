import { NgModule } from '@angular/core';
import { PageComponent } from './page.component';
import { RouterModule } from '@angular/router';

import { CoreModule } from '../core/core.module';
import { ClickOutsideDirective } from '../core/directive/promptclickoutside.directive';
@NgModule({
	declarations: [PageComponent],
	imports: [CoreModule, RouterModule, ClickOutsideDirective]
})
export class PageModule {}

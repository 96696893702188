import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import '@nielsen/nd-ui-components/src/elements.js';
import { ShareReportComponent } from './share-report/share-report.component';
import { CategoryTrendsComponent } from './category-trends/category-trends.component';
import { ProductPerformanceComponent } from './product-performance/product-performance.component';
import { CommonModule } from '@angular/common';
import { ComponentModule } from '../shared/components/selector/component.module';
import {
	ButtonModule,
	CheckboxModule,
	DropDownModule,
	IconModule,
	ItemModule,
	MenuModule,
	SpinnerModule,
	TabModule,
	TooltipModule,
	ToastModule
} from '@nielseniq/athena-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { CMAComparisonComponent } from './share-report/tabs/cma-comparison/cma-comparison.component';
import { PeriodComparisonComponent } from './share-report/tabs/period-comparison/period-comparison.component';
import { ProductComparisonComponent } from './share-report/tabs/product-comparison/product-comparison.component';
import { AssortmentDashboardComponent } from './assortment-dashboard/assortment-dashboard.component';
import { ColorvarientPipe } from '../core/pipes/color-varient.pipe';
import { FormattingCellsPipe } from '../core/pipes/formatting-cells.pipe';
import { TruncateStringPipe } from '../core/pipes/truncate-string.pipe';
import { FormatNumberPipe } from '../core/pipes/format-number.pipe';
import { GridModule } from '../shared/components/grid/grid.module';
import { GeographicDeepDiveComponent } from './geographic-deep-dive/geographic-deep-dive.component';
import { AssortmentWMvsRMInnerTableComponent } from './assortment-dashboard/assortment-wmvs-rm-inner-table/assortment-wmvs-rm-inner-table.component';
import { WalmartPriceBeatsComponent } from './walmart-price-beats/walmart-price-beats.component';
import { MyLimitToPipe } from '../core/pipes/my-limit-to.pipe';
import { PanelDashboardComponent } from './panel-dashboard/panel-dashboard.component';
import { popperVariation, TippyModule } from '@ngneat/helipopper';
import { TextChangePipe } from 'src/app/core/pipes/text-change.pipe';
import { CustomIconComponent } from './share-report/tabs/custom-icon/custom-icon.component';
import { SharedComponentModule } from '../shared/components/shared-component.module';
import { ZipPipe } from '../core/pipes/zip.pipe';
import { ClickOutsideDirective } from '../core/directive/promptclickoutside.directive';
@NgModule({
	declarations: [
		ShareReportComponent,
		CategoryTrendsComponent,
		ProductPerformanceComponent,
		ProductComparisonComponent,
		CMAComparisonComponent,
		PeriodComparisonComponent,
		AssortmentDashboardComponent,
		GeographicDeepDiveComponent,
		AssortmentWMvsRMInnerTableComponent,
		WalmartPriceBeatsComponent,
		PanelDashboardComponent,
		CustomIconComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [],
	providers: [TextChangePipe],
	imports: [
		ClickOutsideDirective,
		ComponentModule,
		CommonModule,
		CheckboxModule,
		ButtonModule,
		TooltipModule,
		IconModule,
		FormsModule,
		ReactiveFormsModule,
		AgGridModule,
		DropDownModule,
		MenuModule,
		ItemModule,
		SpinnerModule,
		TabModule,
		ToastModule,
		ColorvarientPipe,
		FormattingCellsPipe,
		TruncateStringPipe,
		FormatNumberPipe,
		GridModule,
		MyLimitToPipe,
		SharedComponentModule,
		TippyModule.forRoot({
			variations: {
				popper: popperVariation,
				menu: {
					offset: [0, 0],
					hideOnClick: false,
					placement: 'bottom-end',
					interactive: true,
					appendTo: document.body
				}
			}
		}),
		ZipPipe
	]
})
export class ReportModule {}

@if(isRestColumnButton){
<!-- Reset column icon  -->
<div class="text-right pb-3">
	<niq-icon
		state="primary"
		size="medium"
		iconName="reset"
		colorTheme="light"
		[niqTooltip]="'Reset Columns'"
		[niqTooltipShowArrow]="true"
		[niqTooltipPosition]="'left'"
		(click)="onResetColumns()"
	></niq-icon>
</div>
}
<ag-grid-angular
	#agGrid
	id="{{ id }}"
	class="ag-theme-alpine"
	style="width: 100%"
	[style]="customStyle"
	[defaultColDef]="defaultColDef"
	[components]="components"
	[columnDefs]="columns"
	[rowData]="rowData"
	[icons]="icons"
	(gridReady)="onGridReady($event)"
	(cellClicked)="onCellClicked($event)"
	data-test-id="cmn-grid-asset-grid"
	[groupIncludeTotalFooter]="groupIncludeTotalFooter"
	[groupIncludeFooter]="groupIncludeFooter"
	[autoGroupColumnDef]="autoGroup ? autoGroupColumnDef : ''"
	[pinnedTopRowData]="pinnedTopRowData"
	(sortChanged)="onSort($event)"
	[getContextMenuItems]="getContextMenuItems"
>
</ag-grid-angular>

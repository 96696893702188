import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[clickOutside]',
	standalone: true
})
export class TopbarPanelClickOutside {
	@Output() clickOutsideEvent: EventEmitter<void> = new EventEmitter<void>();

	constructor(private elementRef: ElementRef) {}

	@HostListener('document:click', ['$event.target'])
	public onClick(targetElement: HTMLElement) {
		const isClickedInside: boolean = this.elementRef.nativeElement.contains(targetElement);
		console.log(isClickedInside);

		if (!isClickedInside) {
			this.clickOutsideEvent.emit();
		}
	}
}

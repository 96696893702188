<!-- Header START -->
<div class="geoMap">
	<div class="container m-0 p-0">
		<div class="p-3">
			<div class="col-12 d-flex flex-wrap align-items-center">
				<div>
					<div class="mr-4">
						<div class="reportHeader">
							<h4 class="m-0 reportName">{{ pageTitle }}</h4>
						</div>
						<br />
						<div class="mb-0 reportMenu">
							<!-- Fact -->
							<span
								class="report-dropdown filter-container mr-5 p-2"
								*ngIf="headerConfig.basefact.config.available"
								promptClickOutside
								(clickOutside)="this.headerConfig.basefact.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									class="justify-content-between flex-wrap align-items-center"
									[class.disabled]="activeTab === 'table'"
									(click)="toggleDropdown(headerConfig.basefact.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltipColorTheme]="'dark'"
									[niqTooltip]="pageHoverTexts.basefactTooltip"
									niqTooltipPosition="bottom"
									[ngClass]="headerConfig.basefact.ui.display ? 'prompt-expanded' : ''"
								>
									<span>{{ headerConfig.basefact.value.display }}</span>
								</a>
								<app-dropdown-menu
									[hidden]="!headerConfig.basefact.ui.display"
									[items]="headerConfig.basefact.value.dropdown"
									[selectorType]="3"
									(selectedItem)="valueChangeHandler($event, headerConfig.basefact.config.id)"
									(onDefaultChange)="saveResetDefault($event, 'baseFact')"
									(cancel)="toggleDropdown(headerConfig.basefact.config.id)"
								>
								</app-dropdown-menu>
							</span>
							<!-- Product -->
							<span *ngIf="headerConfig.product.config.available">for</span>
							<span
								class="report-dropdown product-filter-container filter-container mr-5 p-2"
								*ngIf="headerConfig.product.config.available"
								#product
								promptClickOutside
								(clickOutside)="this.headerConfig.product.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									class="justify-content-between flex-wrap align-items-center"
									[class.disabled]="headerConfig.product.ui.disabled"
									(click)="toggleDropdown(headerConfig.product.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltipColorTheme]="'dark'"
									[niqTooltip]="pageHoverTexts.productTooltip"
									niqTooltipPosition="bottom"
									[ngClass]="headerConfig.product.ui.display ? 'prompt-expanded' : ''"
								>
									<span>{{ headerConfig.product.value.display[0] }}</span>
									<span
										[title]="headerConfig.product.value.display"
										[ngClass]="headerConfig.product.ui.disabled ? 'disabled' : ''"
										class="badge item-count pl-2"
										*ngIf="headerConfig.product.value.display.length > 1"
									>
										+{{ headerConfig.product.value.display.length - 1 }}</span
									>
								</a>
								<div
									class="product-selector-position"
									*ngIf="headerConfig.product.config.multiSelect"
								>
									<crf-ca-multilayer-dropdown
										*ngIf="headerConfig.product.config.multiSelect"
										[selectorType]="questionId"
										[hidden]="!headerConfig.product.ui.display"
										(onClose)="headerConfig.product.ui.display = false"
										(onApply)="valueChangeHandler($event, headerConfig.product.config.id)"
									>
									</crf-ca-multilayer-dropdown>
								</div>
							</span>
							<!-- Level -->
							<span *ngIf="headerConfig.bylevel.config.available">by</span>
							<span
								class="report-dropdown level-filter-container filter-container mr-5 p-2"
								*ngIf="headerConfig.bylevel.config.available"
								promptClickOutside
								(clickOutside)="this.headerConfig.bylevel.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									class="flex-wrap justify-content-between align-items-center"
									[class.disabled]="headerConfig.bylevel.ui.disabled"
									(click)="toggleDropdown(headerConfig.bylevel.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltipColorTheme]="'dark'"
									[niqTooltip]="pageHoverTexts.levelTooltip"
									niqTooltipPosition="bottom"
									[ngClass]="headerConfig.bylevel.ui.display ? 'prompt-expanded' : ''"
								>
									<span>{{ headerConfig.bylevel.value.display }}</span>
								</a>
								<app-dropdown-menu
									*ngIf="!headerConfig.bylevel.config.multiSelect"
									[hidden]="!headerConfig.bylevel.ui.display"
									[items]="headerConfig.bylevel.value.dropdown"
									[selectorType]="1"
									[configId]="2"
									[showMenu]="false"
									(selectedItem)="valueChangeHandler($event, headerConfig.bylevel.config.id)"
									(cancel)="toggleDropdown(headerConfig.bylevel.config.id)"
								>
								</app-dropdown-menu>
							</span>
							<!-- Period -->
							<span *ngIf="headerConfig.period.config.available">during</span>
							<span
								class="report-dropdown filter-container mr-5 p-2"
								*ngIf="headerConfig.period.config.available"
								promptClickOutside
								(clickOutside)="this.headerConfig.period.ui.display = false"
								(click)="$event.stopPropagation()"
							>
								<a
									class="justify-content-between flex-wrap align-items-center"
									[class.disabled]="headerConfig.period.ui.disabled"
									(click)="toggleDropdown(headerConfig.period.config.id)"
									niqTooltipShowArrow="true"
									[niqTooltipColorTheme]="'dark'"
									[niqTooltip]="pageHoverTexts.periodTooltip"
									niqTooltipPosition="bottom"
									[ngClass]="headerConfig.period.ui.display ? 'prompt-expanded' : ''"
								>
									<span>{{ headerConfig.period.value.display }}</span>
								</a>
								<app-dropdown-menu
									[hidden]="!headerConfig.period.ui.display"
									[items]="headerConfig.period.value.dropdown"
									[selectorType]="5"
									(selectedItem)="valueChangeHandler($event, headerConfig.period.config.id)"
									(onDefaultChange)="saveResetDefault($event, 'periodIds')"
									(cancel)="toggleDropdown(headerConfig.period.config.id)"
								>
								</app-dropdown-menu>
							</span>
						</div>
					</div>
				</div>
				<crf-ca-export-report
					(onExport)="reportDownload($event)"
					[pptIcon]="activeTab === 'map'"
					class="niq-download-icon-report"
				></crf-ca-export-report>
				<crf-ca-save-default
					(toggleOption)="closeAllDropdown()"
					(menuSelector)="saveResetDefault($event, 'all')"
					class="save-selection-all"
					style="top: 4.5rem"
					[selectorType]="7"
					configId="13"
					[disableMenuOptions]="
						defaultPayload?.baseFact === this.baseFact &&
						defaultPayload?.periodIds === headerConfig?.period?.value?.display
					"
				>
				</crf-ca-save-default>
			</div>
		</div>
	</div>
	<!-- Header END -->
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div id="loadData" *ngIf="noData && !this.isLoading">
			<div id="overlay" class="ovt-general-overlay"></div>
			<div class="ovt-no-data-message">
				<span class="glyphicon glyphicon-exclamation-sign"></span>
				<span>No data available</span>
				<span id="closeId" class="ovt-no-data-message-close" (click)="noData = false">×</span>
			</div>
		</div>

		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 order-summary-data">
			<!-- deep dive map table content  -->
			<div class="deep-dive-container">
				<div class="dashboard-secondary-tabs-nav">
					<nav class="navbar">
						<ul class="nav navbar-nav">
							<li [ngClass]="activeTab === 'map' ? 'active' : ''" (click)="onTabChange('map')">
								<a data-target="#map" data-toggle="tab">Map</a>
							</li>
							<li [ngClass]="activeTab === 'table' ? 'active' : ''" (click)="onTabChange('table')">
								<a data-target="#table" data-toggle="tab">{{ selectedMarket }}</a>
							</li>
						</ul>
						<div class="pull-right market-area">
							<span class="page-back-arrow" *ngIf="selectedMarket !== 'Total US'">
								<span class="tooltiptext"
									><niq-icon
										state="primary"
										size="medium"
										iconName="arrow-standard-left
"
										niqTooltipShowArrow="true"
										[niqTooltipColorTheme]="'dark'"
										[niqTooltip]="'Back to TOTAL US'"
										niqTooltipPosition="top"
										colorTheme="light"
										(click)="backToTotalUS()"
									>
										{{ selectedMarket }}
									</niq-icon>
								</span>
							</span>
							<span class="p-2" *ngIf="activeTab !== 'table'">
								<strong>{{ selectedMarket }}</strong>
							</span>
						</div>

						<div
							*ngIf="activeTab === 'table' && columnSortConfig.sortOrder"
							style="float: right; margin-top: 30px; margin-right: 10px; font-size: 13px"
						>
							<span
								><i
									[class]="
										columnSortConfig.sortOrder === 'asc' ? 'fa fa-arrow-up' : 'fa fa-arrow-down'
									"
									aria-hidden="true"
								></i
							></span>
							<span> {{ columnSortConfig.headerName }}</span>
						</div>
					</nav>
				</div>
				<div class="deep-dive-tabs tab-content">
					<!-- Map Data -->
					<div
						class="tab-pane"
						[ngClass]="activeTab === 'map' ? 'active' : ''"
						id="map"
						[hidden]="activeTab === 'table'"
					>
						<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
							<div *ngIf="isLoading">
								<span class="spinner-large">
									<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
								</span>
							</div>
							<!--Map-->
							<div id="mapViewDiv" class="deep-dive-map-height">
								<crf-ca-gis-map
									[hidden]="!isMapDataReady && isLoading"
									(gisClick)="onMapClick($event)"
									(gisMouseEnter)="onMapHover($event)"
									[reportName]="reportName"
								></crf-ca-gis-map>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
							<div class="deep-dive-table-header">
								<h5>
									<span>{{ headerConfig.basefact.value.display }}</span>
									in
									<span>{{ selectedMarket }}</span
									>:
									<span *ngIf="marketTotalUS && selectedMarket === 'Total US'">{{
										marketTotalUS[baseFact] | zip: baseFactSymbol
									}}</span>

									<span *ngIf="marketTotalUS && selectedMarket !== 'Total US'">{{
										selectedRegionValue | zip: baseFactSymbol
									}}</span>
								</h5>
							</div>

							<div class="deep-dive-table-column">
								<span class="spinner-large" *ngIf="isLoading">
									<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
								</span>
								<table class="table table-bordered" aria-describedby="">
									<thead>
										<tr>
											<th scope="col">
												Top 10
												<span>&nbsp; {{ headerConfig.bylevel.value.display }}</span>
											</th>
											<th scope="col">
												<span>{{ headerConfig.basefact.value.display }}</span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											class="deep-beats-no-table"
											*ngIf="positiveMapTableValues.length === 0 && !this.isLoading"
										>
											<td class="table-loader">
												<span class="no-data"> No data available </span>
											</td>
										</tr>
										<tr *ngFor="let item of positiveMapTableValues" [hidden]="isLoading">
											<td>{{ item.prodLevel }}</td>
											<td>
												<span
													[style]="
														baseFact !== 'marketShare' &&
														baseFact !== 'unitsMarketShare' &&
														baseFact !== 'eqUnitsMarketShare'
															? 'color: rgb(38, 185, 16)'
															: ''
													"
												>
													{{ item[baseFact] | zip: baseFactSymbol }}
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div class="clearfix spacer-20"></div>

							<div class="deep-dive-table-column">
								<span class="spinner-large" *ngIf="isLoading">
									<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
								</span>
								<table class="table table-bordered" aria-describedby="">
									<thead>
										<tr>
											<th scope="col">
												Bottom 10
												<span>&nbsp; {{ headerConfig.bylevel.value.display }}</span>
											</th>
											<th scope="col">
												<span>{{ headerConfig.basefact.value.display }}</span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											class="deep-beats-no-table"
											*ngIf="negativeMapTableValues.length === 0 && !this.isLoading"
										>
											<td class="table-loader">
												<span class="no-data"> No data available </span>
											</td>
										</tr>

										<tr *ngFor="let item of negativeMapTableValues" [hidden]="isLoading">
											<td>{{ item.prodLevel }}</td>
											<td>
												<span
													[style]="
														baseFact !== 'marketShare' &&
														baseFact !== 'unitsMarketShare' &&
														baseFact !== 'eqUnitsMarketShare'
															? 'color: rgb(221, 0, 20)'
															: ''
													"
												>
													{{ item[baseFact] | zip: baseFactSymbol }}
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<!-- Table Data -->
					<div>
						<span class="spinner-large" *ngIf="isLoading && activeTab === 'table'">
							<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
						</span>
					</div>
					<div
						class="tab-pane"
						[ngClass]="activeTab === 'table' ? 'active' : ''"
						id="table"
						[hidden]="activeTab === 'map'"
					>
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div class="deep-dive-tab-table">
								<div class="scroll_div_WmRm scroll custom">
									<crf-ag-grid
										[columns]="columDefs"
										[rowData]="metrics"
										*ngIf="!isLoading"
										[reportName]="reportName"
									>
									</crf-ag-grid>
								</div>
							</div>
						</div>
					</div>

					<div class="clearfix spacer-10"></div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xs-12 footnote-block">
		<crf-ca-dashboard-footnote
			[reportDimension]="geographicDeepDiveCtrl"
		></crf-ca-dashboard-footnote>
	</div>
</div>

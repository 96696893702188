import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridComponent } from './grid.component';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { GridCellComponent } from './grid-cell/grid-cell.component';
import { GridHeaderComponent } from './grid-header/grid-header.component';
import { ButtonModule, IconButtonModule, IconModule, TooltipModule } from '@nielseniq/athena-core';
import { ColorvarientPipe } from '../../../core/pipes/color-varient.pipe';
import { FormsModule } from '@angular/forms';
import { ZipPipe } from '../../../core/pipes/zip.pipe';

@NgModule({
	declarations: [GridComponent, GridCellComponent, GridHeaderComponent],
	exports: [GridComponent, GridCellComponent, GridHeaderComponent],
	imports: [
		CommonModule,
		FormsModule,
		AgGridModule,
		IconModule,
		ColorvarientPipe,
		ButtonModule,
		AgGridAngular,
		ZipPipe,
		IconButtonModule,
		TooltipModule
	]
})
export class GridModule {}

<niq-page-header class="page-header-container sticky" variant="default">
	<div class="align-items-stretch d-flex" style="box-shadow: 2px 2px 8px rgb(0 0 0 / 20%)">
		<div class="title d-flex align-items-center flex-grow-1">
			<img
				class="ml-2 logo-padding logo-cursor"
				(click)="navigate()"
				src="assets/img/niqLogo.svg"
				alt="NielsenIQ"
			/>
			<h3 class="m-0 py-3">{{ header.bannerTitleText }}</h3>
		</div>
		<div class="menu-container d-flex align-items-center flex-shrink-0 mr-2">
			<button
				niqTooltip="Contact client services"
				type="image"
				class="button"
				(click)="contactClientDialog.open()"
				[niqTooltipShowArrow]="true"
			>
				<img
					src="assets/img/header-contact-client-services.svg"
					alt="Client-Feedback"
					(click)="contactClientDialog.open()"
					style="color: #060a45"
				/>
			</button>

			<!--Applications-->
			<div clickOutside (clickOutsideEvent)="isAppMenuPanelVisible = false">
				<button
					niqTooltip="Applications"
					type="image"
					class="button"
					(click)="isAppMenuPanelVisible = !isAppMenuPanelVisible"
					[niqTooltipShowArrow]="true"
				>
					<niq-icon
						niqTooltipShowArrow="true"
						size="medium"
						iconName="menu-app-bento"
						style="color: #060a45"
					></niq-icon>
				</button>
				<niq-side-panel
					#sidePanel
					title="Applications"
					titleAlignment="left"
					withClose="true"
					[hidden]="!isAppMenuPanelVisible"
					class="app-menu-side-panel"
				>
					<ng-container side-panel-header-right>
						<niq-icon-button
							size="small"
							iconName="close"
							(click)="isAppMenuPanelVisible = false"
						></niq-icon-button>
					</ng-container>
					<ng-container side-panel-content>
						<crf-ca-app-section
							title=""
							(appMenuSelect)="isAppMenuPanelVisible = false"
							[isConcierge]="false"
						>
						</crf-ca-app-section>
					</ng-container>
				</niq-side-panel>
			</div>

			<!--Notifications-->
			<div clickOutside (clickOutsideEvent)="isNotificationPanelVisible = false">
				<button
					niqTooltip="Notifications"
					type="image"
					class="button"
					(click)="toggleNotifications()"
					[niqTooltipShowArrow]="true"
				>
					<img src="assets/img/alert-icon.svg" alt="Notifications" style="color: #060a45" />
					<div class="red-badge" *ngIf="unreadNotificationCount > 0">
						<img src="assets/img/badge-one.svg" alt="badge" />
					</div>
				</button>
				<niq-side-panel
					class="notification-side-panel"
					#sideNotificationPanel
					title="Notifications"
					titleAlignment="left"
					withClose="true"
					[hidden]="!isNotificationPanelVisible"
				>
					<ng-container side-panel-header-right>
						<niq-icon-button
							size="small"
							iconName="close"
							(click)="isNotificationPanelVisible = false"
						></niq-icon-button>
					</ng-container>
					<ng-container side-panel-content>
						<ul title="">
							<li
								*ngFor="let notification of notifications"
								[class]="{
									'd-flex': true,
									'align-items-center': true,
									'p-4': true,
									unread: notification.isViewed
								}"
								(click)="setNotificationAsRead(notification.messageId)"
							>
								<p class="app-text m-0 p-0">{{ notification.heading }}</p>
								<niq-icon size="small" iconName="arrow-forward-ios-new"></niq-icon>
							</li>
						</ul>
					</ng-container>
				</niq-side-panel>
			</div>

			<!--Profile-->
			<div
				class="profile-container"
				clickOutside
				(clickOutsideEvent)="isProfileMenuVisible = false"
			>
				<button
					type="image"
					class="button-profile profile-container d-flex align-items-center"
					(click)="isProfileMenuVisible = !isProfileMenuVisible"
				>
					<p class="m-0 margin-right-2 user-name user-name-topbar">{{ firstName }}</p>
					<niq-icon
						size="medium"
						iconName="account-outline"
						colorTheme="light"
						class="margin-right-2"
					></niq-icon>
					<niq-icon
						size="medium"
						iconName="chevron-down"
						colorTheme="light"
						class="margin-right-2"
					></niq-icon>
				</button>
				<div class="profile-dropdown" *ngIf="isProfileMenuVisible" (click)="onLogout()">Logout</div>
			</div>

			<button
				type="image"
				class="button"
				routerLink="/training"
				niqTooltip="Training"
				niqTooltipPosition="top"
				niqTooltipShowArrow="true"
			>
				<niq-icon size="medium" iconName="help" style="color: #060a45"></niq-icon>
			</button>
		</div>
	</div>
</niq-page-header>

<!--Message Dialog-->
<niq-dialog #contactClientDialog size="medium" [closeOnClickOutside]="true">
	<div dialogTitle>Message</div>
	<div dialogBody>
		<p>
			For One Version of Truth (OVT) help or support, please contact
			<a target="_blank" href="mailto:Walmart.OVTSupport@smb.nielseniq.com">
				<strong>Walmart.OVTSupport&#64;smb.nielseniq.com</strong> </a
			>.<br />
			Our team is committed to you and we will respond within two (2) business days.
		</p>
	</div>
	<div dialogFooter>
		<niq-button (click)="contactClientDialog.close()" size="medium" variant="primary"
			>OK</niq-button
		>
	</div>
</niq-dialog>

<!--Notification Dialog-->
<niq-dialog #notificationDialog size="medium" [closeOnClickOutside]="true">
	<div dialogTitle>Notifications ({{ notifications.length }})</div>
	<div dialogBody>
		<div class="notifications-container p-3">
			<div
				[class]="{ 'notification-container': true, 'p-4': true, unread: notification.isViewed }"
				*ngFor="let notification of notifications"
			>
				<h4 class="m-0 p-0">{{ notification.heading }}</h4>
				<h5 class="m-0 p-0">{{ notification.subheading }}</h5>
				<small>{{ notification.date | date }}</small>
				<p class="mt-3 p-0">{{ notification.content }}</p>
			</div>
		</div>
	</div>
	<div dialogFooter>
		<niq-button (click)="notificationDialog.close()" size="medium" variant="primary"
			>Close</niq-button
		>
	</div>
</niq-dialog>
